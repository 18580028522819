/*
 * @Description: 
 * @Version: 1.0
 * @Autor: Bbobos
 * @Date: 2020-05-26 14:52:07
 * @LastEditTime: 2020-05-26 14:53:00
 */
import i18n from './i18n.js'

function commonI18n (path) {
  let locale = i18n.locale;
  let messages = i18n.messages;
  let lang = messages[locale];
  let getText = (path) => {
    const array = path.split('.');
    let current = lang;
    let value = ''
    for (let i = 0, j = array.length; i < j; i++) {
      current = current[array[i]]
      if (!current) {
        break;
      }
      if (i === j - 1) {
        value = current;
      }
    }
    return value || path;
  }
  return getText(path)
}

export default commonI18n