<template>
  <div class="login">
    <div class="c">
      <h2 style="text-align:center">
        DAPP
      </h2>
      <el-tabs v-model="activeName">
        <el-tab-pane :label="$t('创建钱包')" name="first"></el-tab-pane>
        <el-tab-pane :label="$t('导入钱包')" name="second"></el-tab-pane>
      </el-tabs>
      <el-form v-if="activeName === 'first'" ref="form" :model="form" label-position="left" label-width="140px">
        <el-form-item :label="$t('钱包名称')" prop="pack_money_name" :rules="[{ required: true, message: $t('请输入昵称'), trigger: 'blur' }]">
          <el-input v-model="form.pack_money_name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('支付密码')" prop="pay_password" :rules="[{ required: true, message: $t('请输入支付密码'), trigger: 'blur' }]">
          <el-input v-model="form.pay_password"></el-input>
        </el-form-item>
        <el-form-item :label="$t('再次输入支付密码')" prop="qr_pay_password" :rules="[{ required: true, message: $t('请再次输入支付密码'), trigger: 'blur' }]">
          <el-input v-model="form.qr_pay_password"></el-input>
        </el-form-item>
        <el-form-item :label="$t('邀请码')" prop="yq_code" :rules="[{ required: true, message: $t('请输入邀请码'), trigger: 'blur' }]">
          <el-input v-model="form.yq_code"></el-input>
        </el-form-item>
        <div class="displayRow justifyCenter alignCenter">
          <!-- :loading="submitLoading" -->
          <el-button type="primary" @click="submitForm('form')">{{$t('提交')}}</el-button>
        </div>
      </el-form>
      <el-form v-else ref="form2" :model="form2" label-position="left" label-width="140px">
        <el-form-item :label="$t('助记词')" prop="zjc_str" :rules="[{ required: true, message: $t('请输入助记词'), trigger: 'blur' }]">
          <el-input v-model="form2.zjc_str" :autosize="{ minRows: 2, maxRows: 4 }" type="textarea" placeholder="Please input"></el-input>
        </el-form-item>
        <el-form-item :label="$t('钱包名称')" prop="pack_money_name" :rules="[{ required: true, message: $t('请输入昵称'), trigger: 'blur' }]">
          <el-input v-model="form2.pack_money_name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('支付密码')" prop="pay_password" :rules="[{ required: true, message: $t('请输入支付密码'), trigger: 'blur' }]">
          <el-input v-model="form2.pay_password"></el-input>
        </el-form-item>
        <el-form-item :label="$t('再次输入支付密码')" prop="qr_pay_password" :rules="[{ required: true, message: $t('请再次输入支付密码'), trigger: 'blur' }]">
          <el-input v-model="form2.qr_pay_password"></el-input>
        </el-form-item>
        <div class="displayRow justifyCenter alignCenter">
          <!-- :loading="submitLoading" -->
          <el-button type="primary" @click="submitForm2('form2')">{{$t('提交')}}</el-button>
        </div>
      </el-form>
    </div>
    <div class="bg">
      <!-- <img :src="bg" alt=""> -->
    </div>
    <!-- 抄写助记词 -->
    <el-dialog :visible.sync="visible1" :title="$t('抄写助记词')" width="60%" center>
      <el-tag class="mr10 mb10" effect="dark" v-for="(i,k) in zjcList" :key="k" type="info">{{i}}</el-tag>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="sureZjc">{{$t('我已抄写助记词')}}</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 校验助记词 -->
    <el-dialog :visible.sync="visible2" :title="$t('验证助记词')" width="60%" center>
      <el-input v-model="mineZjc" :autosize="{ minRows: 2, maxRows: 4 }" type="textarea" placeholder="Please input"></el-input>
      <el-tag class="mt10 mr10" :style="i.isChoose ? '' : 'cursor: pointer'" effect="dark" v-for="(i,k) in zjcList2" :key="k" :type="i.isChoose ? 'info' : ''" @click="choose(i,k)">{{i.val}}</el-tag>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="toSureZjc">{{$t('验证')}}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import basicApi from '@/api/basic'
import message from '@/utils/message'
import md5 from 'js-md5';

export default {
  name: 'login',
  data () {
    return {
      value: 'cn',

      bg: require('@/assets/loginBg.jpeg'),
      activeName: 'first',
      form: {},

      visible1: false,
      uid: '',
      zjcList: [],

      visible2: false,
      zjcList2: [],
      mineZjc: '',

      form2: {},
      submitLoading: false
    }
  },
  created () {
    // var a = {
    //   add_time: 1641479433,
    //   c1: "student",
    //   c2: "apple",
    //   c3: "erupt",
    //   c4: "skin",
    //   c5: "attend",
    //   c6: "explain",
    //   c7: "cycle",
    //   c8: "potato",
    //   c9: "gravity",
    //   c10: "brain",
    //   c11: "fun",
    //   c12: "release",
    //   uid: "43873",
    //   update_time: 1641479433
    // }
    // this.startLogin(a)
    // pause oxygen keen ordinary brief alpha elder machine fun cute target meat
    // student apple erupt skin attend explain cycle potato gravity brain fun release
  },
  watch: {
    activeName (v) {
      this.$nextTick(() => {
        if (v === 'first') {
          this.$refs['form'].clearValidate()
        } else {
          this.$refs['form2'].clearValidate()
        }
      })
    }
  },
  methods: {
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitLoading = true
          var form = JSON.parse(JSON.stringify(this.form))
          form.pay_password = md5(md5(form.pay_password) + '~*-=')
          form.qr_pay_password = md5(md5(form.qr_pay_password) + '~*-=')
          basicApi.login(form).then(res => {
            console.info('->', res);
            if (res.code === '200') {
              message.showNotify(res.msg)
              this.startLogin(res.data.zjcList)
              this.uid = res.data.zjcList.uid
            } else {
              message.showNotify(res.msg, '警告', 'warning')
            }
            this.submitLoading = false
          })
        } else {
          return false;
        }
      });
    },
    async startLogin (res) {
      var a = JSON.parse(JSON.stringify(res))
      delete a.uid
      delete a.add_time
      delete a.update_time
      var arr = []
      for (var b in a) {
        arr.push(a[b])
      }
      this.zjcList = arr
      this.zjcList2 = await this.shuffle(arr)
      this.visible1 = true
    },
    sureZjc () {
      this.mineZjc = ''
      this.visible1 = false
      this.visible2 = true
    },
    choose (i, k) {
      if (!this.zjcList2[k].isChoose) {
        this.zjcList2[k].isChoose = 1
        this.mineZjc += this.zjcList2[k].val + ' '
      } else {
        this.mineZjc = this.mineZjc.replaceAll(this.zjcList2[k].val + ' ', '')
        this.zjcList2[k].isChoose = 0
      }
    },
    toSureZjc () {
      var a = this.zjcList2.filter(e => !e.isChoose)
      if (a && a.length > 0) {
        message.showNotify('请选择完助记词', '警告', 'warning')
        return
      }
      basicApi.zjcVerify(this.mineZjc, this.uid).then(res => {
        if (res.code === '200') {
          message.showNotify(res.msg)
          sessionStorage.setItem('TOKEN', res.data.token)
          sessionStorage.removeItem('home')
          sessionStorage.removeItem('wallet')
          this.$router.push({ path: '/home' })
        } else {
          message.showNotify(res.msg, '警告', 'warning')
        }
      })
    },

    submitForm2 (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitLoading = true
          var form2 = JSON.parse(JSON.stringify(this.form2))
          form2.pay_password = md5(md5(form2.pay_password) + '~*-=')
          form2.qr_pay_password = md5(md5(form2.qr_pay_password) + '~*-=')
          basicApi.exportMoneyPack(form2).then(res => {
            if (res.code === '200') {
              message.showNotify(res.msg)
              sessionStorage.setItem('TOKEN', res.data.token)
              sessionStorage.removeItem('home')
              sessionStorage.removeItem('wallet')
              this.$router.push({ path: '/home' })
            } else {
              message.showNotify(res.msg, '警告', 'warning')
            }
            this.submitLoading = false
          })
        } else {
          return false;
        }
      });
    },

    /****  数组顺序随机打乱(洗牌函数)  ****/
    async shuffle (arr) {
      let _arr = arr.slice()
      for (let i = 0; i < _arr.length; i++) {
        const j = this._getRandomNumber(0, i)
        const temp = _arr[i]
        _arr[i] = _arr[j]
        _arr[j] = temp
      }
      var arrNew = []
      await _arr.forEach(e => {
        arrNew.push({
          val: e,
          isChoose: 0
        })
      })
      return arrNew
    },
    _getRandomNumber (min, max) {
      // min, max 之间的一个数字
      // [0, 1) -> [min, max]
      // [0, 1) -> [0, max - min) -> [min, max)
      // [0, 1) -> [0, max - min + 1) -> [min, max + 1) -> [min, max]
      return Math.floor(Math.random() * (max - min + 1) + min)
    }
  }
}
</script>