/*
 * @Description: 
 * @Version: 1.0
 * @Autor: Bbobos
 * @Date: 2020-03-25 22:09:10
 * @LastEditTime: 2020-05-26 14:52:32
 */

import Vue from 'vue'
import locale from 'element-ui/lib/locale'
import VueI18n from 'vue-i18n'
import messages from './langs'

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: localStorage.lang || 'en',
  messages
})
locale.i18n((key, value) => i18n.t(key, value)) //重点：为了实现element插件的多语言切换


export default i18n