import Vue from 'vue'
import Router from 'vue-router'

const routerPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return routerPush.call(this, location).catch(error => error)
}

import home from '@/views/home/index'
import home_phone from '@/views/phone/home'
import login from '@/views/login'
import login_phone from '@/views/phone/login'
import wallet from '@/views/wallet/index'
import wallet_phone from '@/views/phone/wallet'
import mine from '@/views/mine/index'
import mine_phone from '@/views/phone/mine'

Vue.use(Router)

const routes = [
  { path: '/home', name: 'home', component: home },
  { path: '/home_phone', name: 'home_phone', component: home_phone },
  { path: '/login', name: 'login', component: login },
  { path: '/login_phone', name: 'login_phone', component: login_phone },
  { path: '/wallet', name: 'wallet', component: wallet },
  { path: '/wallet_phone', name: 'wallet_phone', component: wallet_phone },
  { path: '/mine', name: 'mine', component: mine },
  { path: '/mine_phone', name: 'mine_phone', component: mine_phone }
]

const router = new Router({
  mode: 'hash', //后端支持可开
  scrollBehavior: () => ({ y: 0 }),
  routes: routes,
})

export default router
