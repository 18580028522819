import zhLocale from 'element-ui/lib/locale/lang/id'
const id = {
  ...zhLocale,
  '创建钱包': 'create wallet',
  '导入钱包': 'import wallet',
  '钱包名称': 'wallet name',
  '支付密码': 'payment password',
  '再次输入支付密码': 'enter payment password again',
  '邀请码': 'invitation code',
  '助记词': 'mnemonics',
  '请输入昵称': 'please enter a nickname',
  '请输入支付密码': 'please enter payment password',
  '请再次输入支付密码': 'please enter payment password again',
  '请输入邀请码': 'please enter invitation code',
  '请输入助记词': 'please enter mnemonic',
  '提交': 'submit',
  '我已抄写助记词': 'I copied mnemonics',
  '抄写助记词': 'copy mnemonics',
  '验证': 'validation',
  '请选择完助记词': 'please finish selecting mnemonics',
  '警告': 'warning',
  '签到': 'check in',
  '公告': 'announcement',
  '旅游': 'travel',
  '更多': 'more',
  '热门旅游': 'popular travel',
  '总资产': 'total assets',
  '燃料共生': 'fuel symbiosis',
  '共建生态': 'jointly build ecology',
  '资产': 'asset',
  'TT燃料': 'tt fuel',
  'TC燃料': 'tc fuel',
  '慈善旅游基金': 'charitable Tourism Fund',
  '共建数量': 'co construction quantity',
  '共建奖励': 'co construction reward',
  '倍': 'Times',
  '网络错误': 'network error',
  '邀请好友': 'invite friends',
  '我的': 'mine',
  '邀请地址': 'invitation address',
  '复制地址': 'copy address',
  '复制成功': 'copy succeeded',
  '该浏览器不支持自动复制': 'This browser does not support automatic copying',
  'TT 兑换 TC': 'tt # TC',
  '数量': 'quantity',
  '确定': 'OK',
  '请输入数量': 'please enter quantity',
  'TT燃料数量': 'Kwantitas bahan bakar TT',
  'TC燃料数量': 'Kuantitas bahan bakar TC',
  '共生CYTT数量': 'Jumlah cit simbiotis',
  '共生比例': 'Nisbah simbiotis',
  'CYTT B钱包提币': 'Tarik dompet Cyttb',
  '实名认证': 'Autentikasi nama asli',
  '请选择身份进行实名认证': 'Silakan pilih identitas untuk otentikasi nama asli',
  '上传人像面': 'Naik portret',
  '上传国徽面': 'Naikkan emblem nasional',
  '上传图片失败': 'Gagal memuat naik gambar',
  '请输入姓名': 'Silakan masukkan nama Anda',
  '请输入您的身份证号码': 'Silakan masukkan nomor ID Anda',
  '上传手持护照': 'Menguasai paspor yang ditahan tangan',
  '中国用户(仅限中国)': 'Pengguna Cina (Cina saja)',
  '其他国家用户': 'Pengguna di negara lain',
  '请输入YOU2邮箱/交换YOU2点': 'Silakan masukkan kotak surat you2 / titik pertukaran',
  '提币': 'Tarik uang',
  '手续费': 'Charge Servis',
  '请输入提币数量': 'Silakan masukkan kuantitas penarikan',
  '可用': 'tersedia',
  '全部': 'seluruh',
  '验证助记词': 'Memmonik Verifikasi',
  'TT资产列表': 'TT asset list',
  'CYTT资产列表': 'Daftar aset Cytt',
  '提现': 'Pengunduran',
  '资产明细': 'Rincian aset',
  '充值': 'Isi ulang',
  '旅游出行': 'Perjalanan',
  'C2C购买': 'Pembelian C2C',
  '没有更多了': 'Tidak lagi',
  '加速收益': 'Daftar dipercepat',
  '钱包名称已存在': 'Nama dompet sudah ada',
  '提示': 'Tip',
  '兑换': 'exchange',
  '请上传身份证正面照片': 'Silakan mengunggah foto depan kartu ID Anda',
  '请上传身份证反面照片': 'Silakan mengunggah foto di belakang kartu ID Anda',
  '请输入正确身份证号码': 'Silakan masukkan nomor ID yang tepat',
  '可获得TC燃料': 'Bahan bakar TC tersedia',
  '最低1TT开始兑换': 'Minimum 1tt mulai bertukar',
  '钱包导入成功': 'Wallet imported successfully',
  'TT余额不足': 'Insufficient TT balance',
  '订单': 'urutan',
  '购买订单': 'Perintah pembelian',
  '出售订单': 'Perintah penjualan',
  '请选择订单类型查看': 'Silakan pilih tipe perintah untuk dilihat',
  '个人收款码': 'Kode koleksi pribadi',
  '金额': 'jumlah uang',
  'CYTT数量': 'Jumlah cytts',
  '已完成': 'Selesai',
  '已撤单': 'Dibatalkan',
  '进行中': 'memiliki di tangan',
  '我要撤单': 'Aku ingin membatalkan tagihannya.',
  '撤单成功': 'Pengbatalan berhasil',
}
export default id