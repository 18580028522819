<template>
  <div class="login_phone">
    <div class="c">
      <h3 style="text-align:center">
        DAPP
      </h3>
      <van-tabs :active="activeName">
        <van-tab :title="$t('创建钱包')" name="first">
          <van-cell-group>
            <van-field :label="$t('钱包名称')" v-model="form.pack_money_name" :placeholder="$t('请输入昵称')" :border="false" type="textarea" autosize />
            <van-field :label="$t('支付密码')" v-model="form.pay_password" :placeholder="$t('请输入支付密码')" :border="false" type="textarea" autosize />
            <van-field :label="$t('再次输入支付密码')" v-model="form.qr_pay_password" :placeholder="$t('请再次输入支付密码')" :border="false" type="textarea" autosize />
            <van-field :label="$t('邀请码')" v-model="form.yq_code" :placeholder="$t('请输入邀请码')" :border="false" type="textarea" autosize />
          </van-cell-group>
          <div class="mt10 displayRow justifyCenter">
            <el-button type="primary" @click="submitForm">{{$t('提交')}}</el-button>
          </div>
        </van-tab>
        <van-tab :title="$t('导入钱包')" name="second">
          <van-cell-group>
            <van-field :label="$t('助记词')" v-model="form2.zjc_str" :placeholder="$t('请输入助记词')" :border="false" type="textarea" autosize />
            <van-field :label="$t('钱包名称')" v-model="form2.pack_money_name" :placeholder="$t('请输入昵称')" :border="false" type="textarea" autosize />
            <van-field :label="$t('支付密码')" v-model="form2.pay_password" :placeholder="$t('请输入支付密码')" :border="false" type="textarea" autosize />
            <van-field :label="$t('再次输入支付密码')" v-model="form2.qr_pay_password" :placeholder="$t('请再次输入支付密码')" :border="false" type="textarea" autosize />
          </van-cell-group>
          <div class="mt10 displayRow justifyCenter">
            <el-button type="primary" @click="submitForm2">{{$t('提交')}}</el-button>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <van-popup v-model="visible1" position="bottom" :closeable="true">
      <div class="t mb10">{{$t('抄写助记词')}}</div>
      <el-tag class="mr10 mb10" effect="dark" v-for="(i,k) in zjcList" :key="k" type="info">{{i}}</el-tag>
      <div class="displayRow justifyCenter">
        <el-button type="primary" @click="sureZjc">{{$t('我已抄写助记词')}}</el-button>
      </div>
    </van-popup>

    <van-popup v-model="visible2" position="bottom" :closeable="true">
      <div class="t mb10">{{$t('验证助记词')}}</div>
      <el-input v-model="mineZjc" :autosize="{ minRows: 2, maxRows: 4 }" type="textarea" placeholder="Please input" disabled></el-input>
      <el-tag class="mt10 mb10 mr10" :style="i.isChoose ? '' : 'cursor: pointer'" effect="dark" v-for="(i,k) in zjcList2" :key="k" :type="i.isChoose ? 'info' : ''" @click="choose(i,k)">{{i.val}}</el-tag>
      <div class=" displayRow justifyCenter">
        <el-button type="primary" @click="toSureZjc">{{$t('验证')}}</el-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import basicApi from '@/api/basic'
import message from '@/utils/message'
import md5 from 'js-md5';

export default {
  name: 'login',
  computed: {
    isPc () {
      return this.$store.state.isPc
    }
  },
  data () {
    return {
      value: 'cn',

      bg: require('@/assets/loginBg.jpeg'),
      activeName: 'first',
      form: {},

      visible1: false,
      uid: '',
      zjcList: [],

      visible2: false,
      zjcList2: [],
      mineZjc: '',

      form2: {},
      submitLoading: false
    }
  },
  created () {
    // var a = {
    //   add_time: 1641479433,
    //   c1: "student",
    //   c2: "apple",
    //   c3: "erupt",
    //   c4: "skin",
    //   c5: "attend",
    //   c6: "explain",
    //   c7: "cycle",
    //   c8: "potato",
    //   c9: "gravity",
    //   c10: "brain",
    //   c11: "fun",
    //   c12: "release",
    //   uid: "43873",
    //   update_time: 1641479433
    // }
    // this.startLogin(a)
    // pause oxygen keen ordinary brief alpha elder machine fun cute target meat
    // student apple erupt skin attend explain cycle potato gravity brain fun release
  },
  watch: {},
  methods: {
    submitForm () {
      this.submitLoading = true
      var form = JSON.parse(JSON.stringify(this.form))
      form.pay_password = md5(md5(form.pay_password) + '~*-=')
      form.qr_pay_password = md5(md5(form.qr_pay_password) + '~*-=')
      basicApi.login(form).then(res => {
        if (res.code === '200') {
          message.showNotify(res.msg)
          this.startLogin(res.data.zjcList)
          this.uid = res.data.zjcList.uid
        } else {
          message.showNotify(res.msg, '警告', 'warning')
        }
        this.submitLoading = false
      })
    },
    async startLogin (res) {
      var a = JSON.parse(JSON.stringify(res))
      delete a.uid
      delete a.add_time
      delete a.update_time
      var arr = []
      for (var b in a) {
        arr.push(a[b])
      }
      this.zjcList = arr
      this.zjcList2 = await this.shuffle(arr)
      this.visible1 = true
    },
    sureZjc () {
      this.mineZjc = ''
      this.visible1 = false
      this.visible2 = true
    },
    choose (i, k) {
      if (!this.zjcList2[k].isChoose) {
        this.zjcList2[k].isChoose = 1
        this.mineZjc += this.zjcList2[k].val + ' '
      } else {
        this.mineZjc = this.mineZjc.replaceAll(this.zjcList2[k].val + ' ', '')
        this.zjcList2[k].isChoose = 0
      }
    },
    toSureZjc () {
      var a = this.zjcList2.filter(e => !e.isChoose)
      if (a && a.length > 0) {
        message.showNotify('请选择完助记词', '警告', 'warning')
        return
      }
      basicApi.zjcVerify(this.mineZjc, this.uid).then(res => {
        if (res.code === '200') {
          message.showNotify(res.msg)
          sessionStorage.setItem('TOKEN', res.data.token)
          sessionStorage.removeItem('home')
          sessionStorage.removeItem('wallet')
          this.$router.push({ path: '/home_phone' })
        } else {
          message.showNotify(res.msg, '警告', 'warning')
        }
      })
    },

    submitForm2 () {
      this.submitLoading = true
      var form2 = JSON.parse(JSON.stringify(this.form2))
      form2.pay_password = md5(md5(form2.pay_password) + '~*-=')
      form2.qr_pay_password = md5(md5(form2.qr_pay_password) + '~*-=')
      basicApi.exportMoneyPack(form2).then(res => {
        if (res.code === '200') {
          message.showNotify(res.msg)
          sessionStorage.setItem('TOKEN', res.data.token)
          sessionStorage.removeItem('home')
          sessionStorage.removeItem('wallet')
          this.$router.push({ path: '/home_phone' })
        } else {
          message.showNotify(res.msg, '警告', 'warning')
        }
        this.submitLoading = false
      })
    },

    /****  数组顺序随机打乱(洗牌函数)  ****/
    async shuffle (arr) {
      let _arr = arr.slice()
      for (let i = 0; i < _arr.length; i++) {
        const j = this._getRandomNumber(0, i)
        const temp = _arr[i]
        _arr[i] = _arr[j]
        _arr[j] = temp
      }
      var arrNew = []
      await _arr.forEach(e => {
        arrNew.push({
          val: e,
          isChoose: 0
        })
      })
      return arrNew
    },
    _getRandomNumber (min, max) {
      // min, max 之间的一个数字
      // [0, 1) -> [min, max]
      // [0, 1) -> [0, max - min) -> [min, max)
      // [0, 1) -> [0, max - min + 1) -> [min, max + 1) -> [min, max]
      return Math.floor(Math.random() * (max - min + 1) + min)
    }
  }
}
</script>