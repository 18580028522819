<template>
  <div class="app mine-phone" v-loading="pageLoading">
    <div class="mb10 displayColumn alignCenter">
      <h2>{{$t('总资产')}}</h2>
      <div class="t_c mb10 displayRow justifyCenter alignCenter">
        <span>{{ sum_zichan(info.sum_zichan,show) }} USDT</span>
        <i class="ml10 el-icon-view" style="cursor: pointer;" :size="20" @click="isShow"></i>
        <div @click="erweima">
          <svg-icon style="cursor: pointer;" class="ml10" class-name="s20" icon-class="erweima"></svg-icon>
        </div>
      </div>
    </div>

    <van-cell :title="$t('燃料共生')" is-link @click="fun1" />
    <van-cell :title="$t('共建生态')" is-link @click="fun2" />
    <van-cell :title="$t('邀请好友')" is-link @click="invitation" />
    <van-cell :title="$t('TT 兑换 TC')" is-link @click="exchange" />
    <van-cell :title="$t('CYTT B钱包提币')" is-link @click="tb" />
    <van-cell :title="$t('实名认证')" is-link @click="rz" />
    <van-cell :title="$t('订单')" is-link @click="toOrder" />

    <van-popup v-model="visible" position="bottom">
      <div class="t mb10">{{$t('燃料共生')}}</div>
      <div class="tip mb10">{{$t('共生比例')}} 10 TT : 1 TC = 10 CYTT</div>
      <el-form ref="formRef" :model="form" label-width="120px" label-position="left">
        <el-form-item :label="$t('TT燃料数量')" prop="tt" required>
          <el-input v-model="form.tt" type="number" @input="changeTT">
            <template #append>TT</template>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('TC燃料数量')" prop="tc">
          <el-input v-model="form.tc" disabled>
            <template #append>TC</template>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('共生CYTT数量')" prop="cytt">
          <el-input v-model="form.cytt" disabled>
            <template #append>CYTT</template>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('支付密码')" prop="pay_password" required>
          <el-input v-model="form.pay_password">
          </el-input>
        </el-form-item>
      </el-form>
      <div class="submit">
        <el-button @click="visible = false">Cancel</el-button>
        <el-button type="primary" @click="submitFun1" v-loading="formLoading">Confirm</el-button>
      </div>
    </van-popup>
    <van-popup v-model="drawer" position="bottom">
      <div class="t mb10">{{$t('共建生态')}}</div>
      <div class="item displayColumn jusctifyStart alignStart mb10" v-for="(i,k) in gongjianstArr" :key="k">
        <div class="size14">{{i.licai_name}}</div>
        <div class="displayRow justifyBetween alignCenter" style="width:100%">
          <div class="displayColumn justifyStart alignStart">
            <div class="size14">{{$t('共建数量')}}: {{i.min_num + '-' + i.num}} CYTT</div>
            <div class="size14">{{$t('共建奖励')}}: {{i.fangda_beishu + $t('倍')}}</div>
          </div>
          <el-button type="primary" @click="gjst(i)">
            {{$t('共建生态')}}
          </el-button>
        </div>
      </div>
    </van-popup>
    <van-popup class="w9" v-model="visible2" position="center">
      <div class="t mb10 size14">{{dialogT + ' '+ $t('共建生态')}}</div>
      <el-form ref="formRef2" :model="form2" label-width="120px" label-position="left">
        <el-form-item :label="$t('共建数量')" prop="touzi_money" required>
          <el-input v-model="form2.touzi_money" type="number">
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('支付密码')" prop="pay_password" required>
          <el-input v-model="form2.pay_password">
          </el-input>
        </el-form-item>
      </el-form>
      <div class="submit">
        <el-button @click="visible2 = false">Cancel</el-button>
        <el-button type="primary" @click="submitFun2" v-loading="form2Loading">Confirm</el-button>
      </div>
    </van-popup>

    <el-dialog :visible.sync="visible3" :title="$t('邀请好友')" width="90%">
      <div class="mb10">{{$t('邀请码') + ':' + invitationJson.code}}</div>
      <div class="mb10">{{$t('邀请地址') + ':' + invitationJson.qrcode}}</div>
      <template #footer>
        <span class=" dialog-footer">
          <el-button @click="visible3 = false">Cancel</el-button>
          <el-button id="copy_text" ref="copy" data-clipboard-action="copy" :data-clipboard-text="invitationJson.qrcode" @click="toClipboard">{{$t('复制地址')}}</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog :visible.sync="visible4" :title="$t('TT 兑换 TC')" width="90%">
      <el-form :model="exchangeForm" ref="form3">
        <el-form-item :label="$t('数量')" prop="tt_num" label-width="120" :rules="[{ required: true, message: $t('请输入数量'), trigger: 'blur' }]">
          <el-input v-model="exchangeForm.tt_num" type="number"></el-input>
        </el-form-item>
        <div>{{$t('可用')}} {{info.tt}} TT <el-link type="primary" @click.native="setAllTT">{{$t('全部')}}</el-link>
        </div>
        <div v-if="exchangeForm.tt_num">{{$t('可获得TC燃料')}} {{exchangeForm.tt_num / 10}} TC </div>
        <el-form-item :label="$t('支付密码')" prop="pay_password" label-width="120" :rules="[{ required: true, message: $t('请输入支付密码'), trigger: 'blur' }]">
          <el-input v-model="exchangeForm.pay_password"></el-input>
        </el-form-item>
        <div class="displayRow justifyCenter alignCenter">
          <el-button @click="visible4 = false">Cancel</el-button>
          <el-button type="primary" @click="getTtduihuanTc('form3')">{{$t('确定')}}</el-button>
        </div>
      </el-form>
    </el-dialog>

    <el-dialog :title="$t('提示')" :visible.sync="centerDialogVisible" width="90%" center>
      <span>{{$t('请选择身份进行实名认证')}}</span>
      <span slot="footer" class="dialog-footer">
        <el-button class="mb10" type="primary" @click="startReview('c')">{{$t('中国用户(仅限中国)')}}</el-button>
        <el-button class="mb10" type="primary" @click="startReview('e')">{{$t('其他国家用户')}}</el-button>
        <el-button class="mb10" @click="centerDialogVisible = false">cancel</el-button>
      </span>
    </el-dialog>
    <input ref="bonusFile" id="bonusFile" v-show="false" type="file" accept=".jpg, .png" />
    <el-dialog :title="$t('实名认证')" :visible.sync="rzVisible" width="90%">
      <div class="displayColumn justifyStart">
        <div class="displayRow mb10">
          <el-input v-model="rzform.name" :placeholder="$t('请输入姓名')"></el-input>
        </div>
        <div class="displayRow mb10">
          <el-input v-model="rzform.num" :placeholder="$t('请输入您的身份证号码')"></el-input>
        </div>
        <div class="displayRow mb10" v-if="rzType === 'c'">
          <el-button style="height:40px" size="mini" @click="uploadQN('1')">{{$t('上传人像面')}}</el-button>
          <img :key="imgKey" style="width:120px;height:80px" :src="rzform.z_img" alt="" />
          <!-- <img :src="this.img" /> -->
        </div>
        <div class="displayRow mb10" v-if="rzType === 'c'">
          <el-button style="height:40px" size="mini" @click="uploadQN('2')">{{$t('上传国徽面')}}</el-button>
          <img :key="imgKey" style="width:120px;height:80px" :src="rzform.f_img" alt="" />
          <!-- <img :src="this.img" /> -->
        </div>
        <div class="displayRow mb10" v-if="rzType === 'e'">
          <el-button style="height:40px" size="mini" @click="uploadQN('3')">{{$t('上传手持护照')}}</el-button>
          <img :key="imgKey" style="width:120px;height:80px" :src="rzform.user_img" alt="" />
          <!-- <img :src="this.img" /> -->
        </div>
      </div>
      <div class="displayRow justifyCenter alignCenter mt10">
        <el-button type="primary" @click="realRz">{{$t('实名认证')}}</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="visible5" :title="$t('CYTT B钱包提币')" width="90%">
      <div class="displayColumn justifyStart">
        <div class="displayRow mb10 alignCenter">
          <div style="width:160px">{{$t('请输入YOU2邮箱/交换YOU2点')}}</div>
          <el-input v-model="tbform.address" :placeholder="$t('请输入YOU2邮箱/交换YOU2点')"></el-input>
        </div>
        <div class="displayRow mb10 alignCenter">
          <div style="width:160px">{{$t('请输入提币数量')}}</div>
          <el-input v-model="tbform.num" :placeholder="$t('请输入提币数量')"></el-input>
        </div>
        <el-divider class="displayRow justifyCenter">{{$t('可用')}} {{tbInfo.cytt_b_ye}} CYTT <el-link type="primary" @click="setAllCytt">{{$t('全部')}}</el-link>
        </el-divider>

        <div class="displayRow mb10 alignCenter">
          <div style="width:160px">{{$t('手续费')}}</div>
          <el-input v-model="tbInfo.sxf_bl"></el-input>
        </div>
        <div class="displayRow mb10">
          <div style="width:160px">{{$t('请输入支付密码')}}</div>
          <el-input v-model="tbform.pay_password" :placeholder="$t('请输入支付密码')"></el-input>
        </div>
      </div>
      <div class="displayRow justifyCenter alignCenter mt10">
        <el-button type="primary" @click="startTb">{{$t('提币')}}</el-button>
      </div>
    </el-dialog>

    <!-- 0421 -->
    <el-dialog :title="$t('提示')" :visible.sync="orderVisible" width="80%" center>
      <span>{{$t('请选择订单类型查看')}}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="startCheckOrder(1)">{{$t('购买订单')}}</el-button>
        <el-button type="primary" @click="startCheckOrder(2)">{{$t('出售订单')}}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('个人收款码')" :visible.sync="erweimaVisible" width="80%" center>
      <div style="width:100%" class="displayRow justifyCenter">
        <div class="qrcode" style="width:200px;height:200px" ref="qrCodeUrl"></div>
      </div>
    </el-dialog>
    <el-drawer :visible.sync="drawer2" :title="$t(drawer2Tit)" size="360px">
      <div class="infinite-list" v-if="drawer2List && drawer2List.length>0" v-infinite-scroll="load" style="overflow:auto">
        <div class="displayRow justifyBetween alignCenter mb10" v-for="(i,k) in drawer2List" :key="k">
          <div class="displayColumn justifyStart alignStart">
            <div style="font-weight:bold" v-if="orderType === 1">{{$t(i.ni_name)  + '(' + $t(i.status === 2 ? '已完成' : (i.status === 3 ? '已撤单':'进行中')) + ')'}}</div>
            <div style="font-weight:bold" v-else>
              {{$t(i.status === 2 ? '已完成' : (i.status === 3 ? '已撤单':'进行中'))}}
              <el-link @click="chedan(i)" type="primary" v-if="orderType === 2 && i.status != 2 && i.status != 3">{{$t('我要撤单')}}</el-link>
            </div>
            <div>{{$t('CYTT数量') + ':' + i.cytt_num}}</div>
            <div>{{i.update_time}}</div>
          </div>
          <div style="font-weight:bold">{{$t('金额') + i.zongjia}}</div>
        </div>
        <p v-if="noMore">{{$t('没有更多了')}}</p>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import basicApi from '@/api/basic'
import md5 from 'js-md5';
import message from '@/utils/message'
import * as qiniu from 'qiniu-js';

import QRCode from 'qrcodejs2'


export default {
  name: 'wallet',
  computed: {
    sum_zichan () {
      return (a, b) => {
        if (b) {
          return a
        } else {
          return '******'
        }
      }
    },
    img () {
      return 'https://img2.baidu.com/it/u=4118946496,1801917177&fm=253&fmt=auto&app=120&f=JPEG?w=198&h=198'
    }
  },
  data () {
    return {
      pageLoading: false,
      info: {},
      show: true,
      showMore: false,

      visible: false,
      form: {},
      formLoading: false,

      fun2Loading: false,

      drawer: false,
      gongjianstArr: [],

      form2: {},
      dialogT: '',
      dialogRow: {},
      visible2: false,
      form2Loading: false,

      visible3: false,
      invitationJson: {},

      exchangeForm: { tt_num: '', pay_password: '' },
      visible4: false,

      tbInfo: {},
      tbform: {},
      visible5: false,
      centerDialogVisible: false,
      rzType: 'c',
      uploadType: '1',
      rzVisible: false,
      rzform: {},
      imgKey: 1,
      qnyInfo: {},

      orderVisible: false,
      drawer2: false,
      drawer2Tit: '',
      drawer2List: [],
      cyttPage: 1,
      noMore: false,
      orderType: 1,

      erweimaVisible: false
    }
  },
  created () {
    if (!sessionStorage.getItem('wallet')) {
      this.getInfo()
    } else {
      this.info = JSON.parse(sessionStorage.getItem('wallet'))
    }
    this.getQNY()
  },
  mounted () {
    this.$nextTick(() => {
      document.addEventListener("change", (e) => {
        var filesFile = document.getElementById("bonusFile").files[0];
        if (filesFile) {
          this.startUpload(filesFile);
        }
      });
    });
  },
  methods: {
    setAllTT () {
      this.exchangeForm.tt_num = this.info.tt
    },

    getInfo () {
      this.pageLoading = true
      basicApi.getMoneyIndex().then(res => {
        this.pageLoading = false
        this.info = res.data
        sessionStorage.setItem('wallet', JSON.stringify(res.data))
      })
    },
    isShow () {
      this.show = !this.show
    },
    isShowMore () {
      this.showMore = !this.showMore
    },
    fun1 () {
      this.visible = true
    },
    changeTT ($event) {
      this.form.tc = $event / 10
      this.form.cytt = $event
    },
    submitFun1 () {
      this.$refs['formRef'].validate((valid) => {
        if (valid) {
          this.formLoading = true
          basicApi.getRanLiaoGs(this.form.tt, md5(md5(this.form.pay_password) + '~*-=')).then(res => {
            if (res.code === '200') {
              message.showNotify(res.msg)
              this.visible = false
              this.getInfo()
            } else {
              message.showNotify(res.msg, '警告', 'warning')
            }
            this.formLoading = false
          })
        }
      })
    },
    fun2 () {
      this.fun2Loading = true
      basicApi.getGongjianSt().then(res => {
        this.fun2Loading = false
        this.gongjianstArr = res.data
        this.drawer = true
      }).catch(() => {
        this.fun2Loading = false
      })
    },
    gjst (row) {
      this.dialogT = row.licai_name
      this.dialogRow = row
      this.visible2 = true
      this.$nextTick(() => {
        this.$refs['formRef2'].clearValidate()
      })
    },
    submitFun2 () {
      this.$refs['formRef2'].validate((valid) => {
        if (valid) {
          this.form2Loading = true
          var params = {
            // status: this.dialogRow.status,
            status: '2',
            touzi_money: this.form2.touzi_money,
            pay_password: md5(md5(this.form2.pay_password) + '~*-='),
            licai_id: this.dialogRow.id
          }
          basicApi.addGongjianSt(params).then(res => {
            if (res.code === '200') {
              message.showNotify(res.msg)
              this.dialogT = ''
              this.dialogRow = {}
              this.visible2 = false
            } else {
              message.showNotify(res.msg, '警告', 'warning')
            }
            this.form2Loading = false
          })
        }
      })
    },

    invitation () {
      basicApi.yaoQingF().then(res => {
        this.invitationJson = res.data
        this.visible3 = true
      })
    },
    toClipboard () {
      var clipboard = new this.Clipboard("#copy_text");
      clipboard.on("success", (e) => {
        message.showNotify('复制成功')
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        message.showNotify('该浏览器不支持自动复制', '警告', 'warning',);
        // 释放内存
        clipboard.destroy();
      });
    },

    exchange () {
      this.exchangeForm = { tt_num: '', pay_password: '' }
      this.visible4 = true
      this.$nextTick(() => {
        this.$refs['form3'].clearValidate()
      })
    },
    getTtduihuanTc (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          basicApi.getTtduihuanTc({ tt_num: this.exchangeForm.tt_num, pay_password: md5(md5(this.exchangeForm.pay_password) + '~*-='), }).then(res => {
            if (res.code === '200') {
              message.showNotify(res.msg)
            } else {
              message.showNotify(res.msg, '警告', 'warning')
            }
            this.visible4 = false
            this.getInfo()
          })
        }
      })
    },


    tb () {
      basicApi.getIsShiming().then(res => {
        if (res.data.is_real) {
          basicApi.tibiPage3().then(res => {
            this.tbInfo = res.data
            this.visible5 = true
          })
        } else {
          message.showNotify(res.msg, '警告', 'warning')
        }
      })
    },
    setAllCytt () {
      this.tbform.num = this.tbInfo.cytt_b_ye
    },
    startTb () {
      var form = JSON.parse(JSON.stringify(this.tbform))
      form.pay_password = md5(md5(form.pay_password) + '~*-=')
      form.bz_id = '3'
      basicApi.tibiMethod(form).then(res => {
        if (res.code === '200') {
          message.showNotify(res.msg)
          this.visible5 = false
        } else {
          message.showNotify(res.msg, '警告', 'warning')
        }
      })
    },

    // rz
    rz () {
      this.rzform = {}
      this.centerDialogVisible = true
    },
    startReview (type) {
      this.rzType = type
      this.rzVisible = true
      this.centerDialogVisible = false
    },
    uploadQN (type) {
      this.uploadType = type
      const downloadElement = document.getElementById("bonusFile");
      downloadElement.click();
    },
    startUpload (e) {
      const file = e;
      const key = e.name;
      const token = this.qnyInfo.update_token; //从服务器拿的并存在本地data里
      const putExtra = {
        fname: '',
        params: {},
        mimeType: ['image/png', 'image/jpeg', 'image/gif'],
      };
      const config = {
        useCdnDomain: true,
        region: qiniu.region.z2
      };
      const observable = qiniu.upload(file, key, token, putExtra, config);
      observable.subscribe({
        next: (result) => {
          // 主要用来展示进度
          console.warn(result);
        },
        error: () => {
          message.showNotify('上传图片失败', '警告', 'warning')
          document.getElementById("bonusFile").value = '';
        },
        complete: (res) => {
          console.log(res.key);
          if (this.uploadType === '1') {
            this.rzform.z_img = 'https://cytt2.turingnetwork.com/' + res.key
          } else if (this.uploadType === '2') {
            this.rzform.f_img = 'https://cytt2.turingnetwork.com/' + res.key
          } else {
            this.rzform.user_img = 'https://cytt2.turingnetwork.com/' + res.key
          }
          ++this.imgKey
          document.getElementById("bonusFile").value = '';
        },
      });
    },
    realRz () {
      if (this.rzType === 'c') {
        basicApi.isCertification(this.rzform).then(res => {
          if (res.code === '200') {
            message.showNotify(res.msg)
            this.rzVisible = false
          } else {
            message.showNotify(res.msg, '警告', 'warning')
          }
        })
      } else {
        basicApi.isOthercountry(this.rzform).then(res => {
          if (res.code === '200') {
            message.showNotify(res.msg)
            this.rzVisible = false
          } else {
            message.showNotify(res.msg, '警告', 'warning')
          }
        })
      }

    },

    async erweima () {
      this.erweimaVisible = true
      if (!this.$refs.qrCodeUrl) {
        this.$nextTick(async () => {
          await this.creatQrCode();
        })
      }

    },
    creatQrCode () {
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: 'user:' + JSON.parse(sessionStorage.getItem('home')).address,
        width: 200,
        height: 200,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    },
    toOrder () {
      this.orderVisible = true
    },
    startCheckOrder (type) {
      this.orderVisible = false
      this.orderType = type
      if (type === 1) {
        this.drawer2Tit = '购买订单'
      } else {
        this.drawer2Tit = '出售订单'
      }
      this.getOrderList()
    },
    getOrderList () {
      this.cyttPage = 1
      this.noMore = false
      basicApi.getOrderList(this.cyttPage, this.orderType).then(res => {
        this.drawer2List = res.data
        this.drawer2 = true
      })
    },
    load () {
      if (this.noMore) return
      ++this.cyttPage
      basicApi.getOrderList(this.cyttPage, this.orderType).then(res => {
        this.drawer2List = this.drawer2List.concat(res.data)
        if (res.data.length < 1) {
          this.noMore = true
        }
      })
    },
    chedan (i) {
      basicApi.delOrderById(i.id).then(res => {
        if (res.code === '200') {
          message.showNotify(res.msg)
          this.drawer2 = false
        } else {
          message.showNotify(res.msg, '警告', 'warning')
        }
      })
    },
    /****  default  ****/
    getQNY () {
      basicApi.getQNY().then(res => {
        this.qnyInfo = res.data
      })
    }
  }
}
</script>