import jaLocale from 'element-ui/lib/locale/lang/ja'
const ja = {
  ...jaLocale,
  '创建钱包': 'ウォレットの作成',
  '导入钱包': 'ウォレットのインポート',
  '钱包名称': 'ウォレット名',
  '支付密码': '支払いパスワード',
  '再次输入支付密码': '支払パスワードの再入力',
  '邀请码': '招待コード',
  '助记词': '助記語',
  '请输入昵称': 'ニックネームを入力してください',
  '请输入支付密码': '支払いパスワードを入力してください',
  '请再次输入支付密码': '支払いパスワードを再度入力してください',
  '请输入邀请码': '招待コードを入力してください',
  '请输入助记词': '助数詞を入力してください',
  '提交': '送信',
  '我已抄写助记词': '私は助記詞を写した。',
  '抄写助记词': '助記を写す',
  '验证': '検証＃ケンショウ＃',
  '请选择完助记词': '助数詞を選択してください',
  '警告': '警告',
  '签到': 'にサイン',
  '公告': '公告',
  '旅游': '観光',
  '更多': 'その他',
  '热门旅游': '人気旅行',
  '总资产': '総資産',
  '燃料共生': '燃料共生',
  '共建生态': '共同で生態をつくる.',
  '资产': '資産',
  'TT燃料': 'TT燃料',
  'TC燃料': 'TC燃料',
  '慈善旅游基金': '慈善旅行基金',
  '共建数量': '共同建設数量',
  '共建奖励': '共同建設奨励',
  '倍': '倍',
  '网络错误': 'ネットワークエラー',
  '邀请好友': '友達を誘う',
  '我的': '私の',
  '邀请地址': '招待先',
  '复制地址': 'アドレスのコピー',
  '复制成功': 'コピー成功',
  '该浏览器不支持自动复制': 'このブラウザでは自動レプリケーションはサポートされていません',
  'TT 兑换 TC': 'TT両替TC',
  '数量': '数量',
  '确定': 'を選択します。',
  '请输入数量': '数量を入力してください',
  'TT燃料数量': 'TT燃料数',
  'TC燃料数量': 'TC燃料数',
  '共生CYTT数量': '共生CYTT数',
  '共生比例': '共生割合',
  'CYTT B钱包提币': 'CYTTB財布引き出し',
  '实名认证': '実名認証',
  '请选择身份进行实名认证': '実名認証のためにアイデンティティを選択してください',
  '上传人像面': '人像面をアップロード',
  '上传国徽面': '国章面をアップロードする',
  '上传图片失败': '画像のアップロードに失敗しました',
  '请输入姓名': '名前を入力してください',
  '请输入您的身份证号码': '身分証明書番号を入力してください',
  '上传手持护照': 'パスポートをアップロード',
  '中国用户(仅限中国)': '中国ユーザー（中国のみ）',
  '其他国家用户': 'その他の国のユーザー',
  '请输入YOU2邮箱/交换YOU2点': 'YOU 2メールアドレス/交換YOU 2ポイントを入力してください',
  '提币': '貨幣を引き出す',
  '手续费': '手数料',
  '请输入提币数量': '引き出し数量を入力してください',
  '可用': '使用可能',
  '全部': 'すべて',
  '验证助记词': 'メモの検証',
  'TT资产列表': 'TT資産リスト',
  'CYTT资产列表': 'CYTT資産リスト',
  '提现': '現金を引き出す',
  '资产明细': '資産詳細',
  '充值': 'チャージ',
  '旅游出行': '旅行に出かける',
  'C2C购买': 'C 2 C購入',
  '没有更多了': 'これ以上ない',
  '加速收益': '収益の加速',
  '钱包名称已存在': 'ウォレット名は既に存在します',
  '提示': 'ヒント',
  '兑换': '両替',
  '请上传身份证正面照片': '身分証の正面写真をアップロードしてください',
  '请上传身份证反面照片': '身分証明書の裏面写真をアップロードしてください',
  '请输入正确身份证号码': '正しい身分証明書番号を入力してください',
  '可获得TC燃料': 'TC燃料を得ることができる',
  '最低1TT开始兑换': '最低1 TTから両替開始',
  '钱包导入成功': 'ウォレットのインポートに成功しました',
  'TT余额不足': 'TT残高不足',
  '订单': 'オーダー',
  '购买订单': '購入オーダー',
  '出售订单': '販売オーダ',
  '请选择订单类型查看': 'オーダー・タイプの表示を選択してください',
  '个人收款码': '個人入金コード',
  '金额': '金額',
  'CYTT数量': 'CYTT数量',
  '已完成': '完了',
  '已撤单': 'キャンセル済み',
  '进行中': '進行中',
  '我要撤单': '注文を撤回します。',
  '撤单成功': 'キャンセル成功',
}
export default ja