import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'element-ui/lib/theme-chalk/display.css';
import './plugins/element.js'
import '@/icons' // icon
import '@/styles/index.scss' // global css

import i18n from '@/assets/i18n/i18n'

import Vant from 'vant';
import 'vant/lib/index.css';
import "@/styles/vant.js"; // 在这里引入你所需的组件
Vue.use(Vant);
import Clipboard from 'clipboard';

Vue.prototype.Clipboard = Clipboard
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
