import thLocale from 'element-ui/lib/locale/lang/th'
const th = {
  ...thLocale,
  '创建钱包': 'สร้างกระเป๋าสตางค์',
  '导入钱包': 'กระเป๋าสตางค์นำเข้า',
  '钱包名称': 'ชื่อกระเป๋าสตางค์',
  '支付密码': 'จ่ายรหัสผ่าน',
  '再次输入支付密码': 'ป้อนรหัสผ่านการชำระเงินอีกครั้ง',
  '邀请码': 'รหัสเชิญ',
  '助记词': 'พยัญชนะภาษาอังกฤษตัวที่',
  '请输入昵称': 'กรุณาใส่ชื่อเล่น',
  '请输入支付密码': 'กรุณาใส่รหัสผ่านการชำระเงิน',
  '请再次输入支付密码': 'กรุณาใส่รหัสผ่านการชำระเงินอีกครั้ง',
  '请输入邀请码': 'กรุณาใส่รหัสเชิญ',
  '请输入助记词': 'กรุณาใส่ช่วยจำ',
  '提交': 'ทูลเกล้าฯถวาย',
  '我已抄写助记词': 'ฉันได้เขียนช่วยจำ',
  '抄写助记词': 'เขียนคำช่วยจำ',
  '验证': 'สิ่งขัดขวาง',
  '请选择完助记词': 'กรุณาเลือกคำเสริม',
  '警告': 'เตือนภัย',
  '签到': 'ทำเครื่องหมาย',
  '公告': 'ประกาศ',
  '旅游': 'การท่องเที่ยว',
  '更多': 'ยิ่งขึ้น',
  '热门旅游': 'ทัวร์ยอดนิยม',
  '总资产': 'สินทรัพย์ทั้งหมด',
  '燃料共生': 'เชื้อเพลิง symbiosis',
  '共建生态': 'นิเวศวิทยา Co ก่อสร้าง',
  '资产': 'ทรัพย์สิน',
  'TT燃料': 'เชื้อเพลิง',
  'TC燃料': 'เชื้อเพลิง',
  '慈善旅游基金': 'มูลนิธิการท่องเที่ยวการกุศล',
  '共建数量': 'จำนวนรวม',
  '共建奖励': 'ร่วมสร้างรางวัล',
  '倍': 'คราว',
  '网络错误': 'ข้อผิดพลาดของเครือข่าย',
  '邀请好友': 'เชิญเพื่อน',
  '我的': 'ของฉัน',
  '邀请地址': 'ที่อยู่เชิญ',
  '复制地址': 'คัดลอกที่อยู่',
  '复制成功': 'คัดลอกสำเร็จ',
  '该浏览器不支持自动复制': 'เบราว์เซอร์นี้ไม่สนับสนุนการคัดลอกอัตโนมัติ',
  'TT 兑换 TC': 'ทีทีแลกเปลี่ยน',
  '数量': 'จำนวนรวม',
  '确定': 'ยืนยัน',
  '请输入数量': 'โปรดระบุหมายเลข',
  'TT燃料数量': 'ปริมาณเชื้อเพลิง',
  'TC燃料数量': 'ปริมาณเชื้อเพลิง',
  '共生CYTT数量': 'จำนวน cytt symbiosis',
  '共生比例': 'สัดส่วน symbiotic',
  'CYTT B钱包提币': 'กระเป๋าสตางค์สกัด',
  '实名认证': 'การรับรองชื่อจริง',
  '请选择身份进行实名认证': 'กรุณาเลือกชื่อจริงของคุณสำหรับการตรวจสอบ',
  '上传人像面': 'อัพโหลดหน้า',
  '上传国徽面': 'อัพโหลดตราแผ่นดิน',
  '上传图片失败': 'ล้มเหลวในการอัพโหลดรูปภาพ',
  '请输入姓名': 'กรุณาใส่ชื่อ',
  '请输入您的身份证号码': 'กรุณาใส่หมายเลขบัตรประชาชนของคุณ',
  '上传手持护照': 'อัพโหลดพาสปอร์ตมือถือ',
  '中国用户(仅限中国)': 'ผู้ใช้ภาษาจีนเท่านั้น',
  '其他国家用户': 'ผู้ใช้คนอื่น',
  '请输入YOU2邮箱/交换YOU2点': 'กรุณาระบุจุดแลกเปลี่ยน YOU2 กล่องจดหมาย',
  '提币': 'สกัดออกมา',
  '手续费': 'ค่าธรรมเนียม',
  '请输入提币数量': 'กรุณาใส่หมายเลขสกุลเงิน',
  '可用': 'ใช้',
  '全部': 'จำนวนทั้งหมด',
  '验证助记词': 'ตรวจสอบช่วยจำคำ',
  'TT资产列表': 'รายชื่อสินทรัพย์',
  'CYTT资产列表': 'รายชื่อสินทรัพย์ CYTT',
  '提现': 'ถอนเงิน',
  '资产明细': 'รายละเอียดสินทรัพย์',
  '充值': 'เติม',
  '旅游出行': 'ท่องเที่ยว',
  'C2C购买': 'ซื้อ',
  '没有更多了': 'ไม่อีกแล้ว',
  '加速收益': 'เร่งรายได้',
  '钱包名称已存在': 'ชื่อกระเป๋าอยู่แล้ว',
  '提示': 'ทูลเกล้าฯถวาย',
  '兑换': 'คราว',
  '请上传身份证正面照片': 'กรุณาอัพโหลดรูปด้านหน้าของบัตรประชาชน',
  '请上传身份证反面照片': 'กรุณาอัพโหลดรูปด้านหลังของบัตรประชาชน',
  '请输入正确身份证号码': 'กรุณาใส่หมายเลขบัตรประชาชนที่ถูกต้อง',
  '可获得TC燃料': 'เชื้อเพลิงทีซี',
  '最低1TT开始兑换': 'เริ่มแลกเงินขั้นต่ำ',
  '钱包导入成功': 'กระเป๋าสตางค์นำเข้าสำเร็จ',
  'TT余额不足': 'ความสมดุลของ TT ไม่เพียงพอ',
  '订单': 'คำสั่ง',
  '购买订单': 'สั่งซื้อสินค้า',
  '出售订单': 'สั่งซื้อขาย',
  '请选择订单类型查看': 'กรุณาเลือกประเภทการสั่งซื้อเพื่อดู',
  '个人收款码': 'รหัสบัญชีส่วนบุคคล',
  '金额': 'ผลรวม',
  'CYTT数量': 'หมายเลข cytt',
  '已完成': 'เสร็จสิ้น',
  '已撤单': 'ถอนคำพูด',
  '进行中': 'เจริญก้าวหน้า',
  '我要撤单': 'ฉันต้องการถอนบิล',
  '撤单成功': 'ถอนคำสั่งสำเร็จ',
}
export default th