import zhLocale from 'element-ui/lib/locale/lang/vi'
const vi = {
  ...zhLocale,
  '创建钱包': 'Tạo ví',
  '导入钱包': 'Nhập Ví',
  '钱包名称': 'Tên Ví',
  '支付密码': 'Mật khẩu',
  '再次输入支付密码': 'Nhập lại mật khẩu thanh toán',
  '邀请码': 'Mã mời',
  '助记词': 'Từ Mnemonic',
  '请输入昵称': 'Hãy nhập một biệt danh',
  '请输入支付密码': 'Hãy nhập mật khẩu thanh toán.',
  '请再次输入支付密码': 'Xin nhập lại mật khẩu thanh toán.',
  '请输入邀请码': 'Hãy nhập mã thư mời.',
  '请输入助记词': 'Hãy nhập nội dung',
  '提交': 'Gởi',
  '我已抄写助记词': 'Tôi đã bắt chước báo động',
  '抄写助记词': 'Chép ngữ thức',
  '验证': 'kiểm tra',
  '请选择完助记词': 'Hãy chọn báo động',
  '警告': 'cảnh báo',
  '签到': 'Ký vô.',
  '公告': 'Ghi',
  '旅游': 'Du lịch?',
  '更多': 'thêm',
  '热门旅游': 'Du lịch dân',
  '总资产': 'Tổng kích hoạt',
  '燃料共生': 'Phong tượng nhiên liệu',
  '共建生态': 'Công nghệ xây dựng',
  '资产': 'khai',
  'TT燃料': 'Nhiên liệu lưới',
  'TC燃料': 'Nhiên liệu TC',
  '慈善旅游基金': 'Quỹ Quỹ Xây Du',
  '共建数量': 'Tăng số xây dựng',
  '共建奖励': 'Tăng tiền xây dựng',
  '倍': 'Thời',
  '网络错误': 'lỗi mạng',
  '邀请好友': 'Bạn mời',
  '我的': 'của',
  '邀请地址': 'Mời địa chỉ',
  '复制地址': 'Chép địa chỉ',
  '复制成功': 'Chép thành công',
  '该浏览器不支持自动复制': 'Bộ duyệt không hỗ trợ tái tạo tự động.',
  'TT 兑换 TC': 'T cho TC',
  '数量': 'Số',
  '确定': 'xác định',
  '请输入数量': 'Nhập số',
  'TT燃料数量': 'Định lượng nhiên liệu',
  'TC燃料数量': 'Chỉ số nhiên liệu',
  '共生CYTT数量': 'Số rung động cộng sinh',
  '共生比例': 'Tỷ lệ ký sinh',
  'CYTT B钱包提币': 'Buồn mua ảo',
  '实名认证': 'Xác thực tên thật',
  '请选择身份进行实名认证': 'Hãy chọn danh tính để xác thực tên thật',
  '上传人像面': 'Tải chân dung lên',
  '上传国徽面': 'Tải quốc gia lên',
  '上传图片失败': 'Lỗi tải ảnh',
  '请输入姓名': 'Hãy nhập tên của bạn',
  '请输入您的身份证号码': 'Hãy nhập số nhận diện',
  '上传手持护照': 'Tải hộ chiếu trên tay',
  '中国用户(仅限中国)': 'Người dùng Trung Quốc (chỉ Trung Quốc)',
  '其他国家用户': 'Người dùng ở các nước khác',
  '请输入YOU2邮箱/交换YOU2点': 'Hãy nhập nhỏ thùng thư/ địa chỉ trao đổi',
  '提币': 'Rút tiền.',
  '手续费': 'Phí dịch vụ',
  '请输入提币数量': 'Nhập số lượng rút lui vào',
  '可用': 'có',
  '全部': 'nguyên',
  '验证助记词': 'Thanh ngữ kiểm tra',
  'TT资产列表': 'Hệ thống công ty T',
  'CYTT资产列表': 'Danh sách tài sản Cytt',
  '提现': 'Rút',
  '资产明细': 'Chi tiết tài sản',
  '充值': 'Nạp',
  '旅游出行': 'Du',
  'C2C购买': 'Giá cặp đôi',
  '没有更多了': 'Không còn nữa.',
  '加速收益': 'Tăng thu nhập',
  '钱包名称已存在': 'Tên Ví đã có',
  '提示': 'Mẹo',
  '兑换': 'trao',
  '请上传身份证正面照片': 'Vui lòng tải ảnh trước của thẻ ID lên',
  '请上传身份证反面照片': 'Vui lòng tải ảnh lên mặt sau thẻ ID của bạn',
  '请输入正确身份证号码': 'Hãy nhập số nhận diện đúng',
  '可获得TC燃料': 'Chỉ nhiên liệu',
  '最低1TT开始兑换': 'Bộ giao dịch tối thiểu',
  '钱包导入成功': 'Ví nhập thành công',
  'TT余额不足': 'Thiếu hụt cán cân lưới',
  '订单': 'Thứ',
  '购买订单': 'Lệnh mua hàng',
  '出售订单': 'Yêu cầu bán',
  '请选择订单类型查看': 'Hãy chọn kiểu lệnh cần xem',
  '个人收款码': 'Số bộ sưu tập cá nhân',
  '金额': 'bao nhiêu tiền',
  'CYTT数量': 'Số Cá mập',
  '已完成': 'Hoàn',
  '已撤单': 'Dừng',
  '进行中': 'có trong tay',
  '我要撤单': 'Tôi muốn hủy dự luật.',
  '撤单成功': 'Kết thúc',
}
export default vi