/*
 * @Description: 中文包 zh
 * @Version: 1.0
 * @Autor: Bbobos
 * @Date: 2020-03-25 14:25:25
 * @LastEditTime: 2021-02-20 09:32:07
 */

import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
const zh = {
  ...zhLocale,
  '创建钱包': '创建钱包',
  '导入钱包': '导入钱包',
  '钱包名称': '钱包名称',
  '支付密码': '支付密码',
  '再次输入支付密码': '再次输入支付密码',
  '邀请码': '邀请码',
  '助记词': '助记词',
  '请输入昵称': '请输入昵称',
  '请输入支付密码': '请输入支付密码',
  '请再次输入支付密码': '请再次输入支付密码',
  '请输入邀请码': '请输入邀请码',
  '请输入助记词': '请输入助记词',
  '提交': '提交',
  '我已抄写助记词': '我已抄写助记词',
  '抄写助记词': '抄写助记词',
  '验证': '验证',
  '请选择完助记词': '请选择完助记词',
  '警告': '警告',
  '签到': '签到',
  '公告': '公告',
  '旅游': '旅游',
  '更多': '更多',
  '热门旅游': '热门旅游',
  '总资产': '总资产',
  '燃料共生': '燃料共生',
  '共建生态': '共建生态',
  '资产': '资产',
  'TT燃料': 'TT燃料',
  'TC燃料': 'TC燃料',
  '慈善旅游基金': '慈善旅游基金',
  '共建数量': '共建数量',
  '共建奖励': '共建奖励',
  '倍': '倍',
  '网络错误': '网络错误',
  '邀请好友': '邀请好友',
  '我的': '我的',
  '邀请地址': '邀请地址',
  '复制地址': '复制地址',
  '复制成功': '复制成功',
  '该浏览器不支持自动复制': '该浏览器不支持自动复制',
  'TT 兑换 TC': 'TT 兑换 TC',
  '数量': '数量',
  '确定': '确定',
  '请输入数量': '请输入数量',
  'TT燃料数量': 'TT燃料数量',
  'TC燃料数量': 'TC燃料数量',
  '共生CYTT数量': '共生CYTT数量',
  '共生比例': '共生比例',
  'CYTT B钱包提币': 'CYTT B钱包提币',
  '实名认证': '实名认证',
  '请选择身份进行实名认证': '请选择身份进行实名认证',
  '上传人像面': '上传人像面',
  '上传国徽面': '上传国徽面',
  '上传图片失败': '上传图片失败',
  '请输入姓名': '请输入姓名',
  '请输入您的身份证号码': '请输入您的身份证号码',
  '上传手持护照': '上传手持护照',
  '中国用户(仅限中国)': '中国用户(仅限中国)',
  '其他国家用户': '其他国家用户',
  '请输入YOU2邮箱/交换YOU2点': '请输入YOU2邮箱/交换YOU2点',
  '提币': '提币',
  '手续费': '手续费',
  '请输入提币数量': '请输入提币数量',
  '可用': '可用',
  '全部': '全部',
  '验证助记词': '验证助记词',
  'TT资产列表': 'TT资产列表',
  'CYTT资产列表': 'CYTT资产列表',
  '提现': '提现',
  '资产明细': '资产明细',
  '充值': '充值',
  '旅游出行': '旅游出行',
  'C2C购买': 'C2C购买',
  '没有更多了': '没有更多了',
  '加速收益': '加速收益',
  '钱包名称已存在': '钱包名称已存在',
  '提示': '提示',
  '兑换': '兑换',
  '请上传身份证正面照片': '请上传身份证正面照片',
  '请上传身份证反面照片': '请上传身份证反面照片',
  '请输入正确身份证号码': '请输入正确身份证号码',
  '可获得TC燃料': '可获得TC燃料',
  '最低1TT开始兑换': '最低1TT开始兑换',
  '钱包导入成功': '钱包导入成功',
  'TT余额不足': 'TT余额不足',
  '订单': '订单',
  '购买订单': '购买订单',
  '出售订单': '出售订单',
  '请选择订单类型查看': '请选择订单类型查看',
  '个人收款码': '个人收款码',
  '金额': '金额',
  'CYTT数量': 'CYTT数量',
  '已完成': '已完成',
  '已撤单': '已撤单',
  '进行中': '进行中',
  '我要撤单': '我要撤单',
  '撤单成功': '撤单成功',
}
export default zh