import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    fullHeight: 0,
    isPc: true
  },
  mutations: {
    changeFullHeight: (state, val) => {
      state.fullHeight = val
    },
    changeIsPc: (state, val) => {
      state.isPc = val
    },
  },
  actions: {
  },
  modules: {
  }
})
