import enLocale from 'element-ui/lib/locale/lang/en'
const en = {
  ...enLocale,
  '创建钱包': 'Create Wallet',
  '导入钱包': 'Import Wallet',
  '钱包名称': 'Wallet name',
  '支付密码': 'Payment password',
  '再次输入支付密码': 'Enter the payment password again',
  '邀请码': 'Invitation code',
  '助记词': 'Mnemonic words',
  '请输入昵称': 'Please enter a nickname',
  '请输入支付密码': 'Please enter the payment password',
  '请再次输入支付密码': 'Please enter the payment password again',
  '请输入邀请码': 'Please enter the invitation code',
  '请输入助记词': 'Please enter mnemonic',
  '提交': 'Submit',
  '我已抄写助记词': 'I have copied mnemonics',
  '抄写助记词': 'Copy mnemonics',
  '验证': 'verification',
  '请选择完助记词': 'Please select mnemonics',
  '警告': 'Warning',
  '签到': 'Sign in',
  '公告': 'Notice',
  '旅游': 'Travel',
  '更多': 'More',
  '热门旅游': 'Popular tourism',
  '总资产': 'Total assets',
  '燃料共生': 'Fuel symbiosis',
  '共建生态': 'Co construction ecology',
  '资产': 'Assets',
  'TT燃料': 'TT fuel',
  'TC燃料': 'TC fuel',
  '慈善旅游基金': 'Charitable Tourism Fund',
  '共建数量': 'Co construction quantity',
  '共建奖励': 'Co construction reward',
  '倍': 'multiple',
  '网络错误': 'network error',
  '邀请好友': 'Invite friends',
  '我的': 'my',
  '邀请地址': 'Invitation address',
  '复制地址': 'Copy address',
  '复制成功': 'Copy succeeded',
  '该浏览器不支持自动复制': 'The browser does not support automatic replication',
  'TT 兑换 TC': 'TT exchange TC',
  '数量': 'quantity',
  '确定': 'determine',
  '请输入数量': 'Please enter quantity',
  'TT燃料数量': 'TT fuel quantity',
  'TC燃料数量': 'TC fuel quantity',
  '共生CYTT数量': 'Number of symbiotic cytts',
  '共生比例': 'Symbiotic ratio',
  'CYTT B钱包提币': 'Cyttb wallet withdrawal',
  '实名认证': 'Real name authentication',
  '请选择身份进行实名认证': 'Please select identity for real name authentication',
  '上传人像面': 'Upload portrait',
  '上传国徽面': 'Upload the national emblem',
  '上传图片失败': 'Failed to upload picture',
  '请输入姓名': 'Please enter your name',
  '请输入您的身份证号码': 'Please input your ID number.',
  '上传手持护照': 'Upload a hand-held passport',
  '中国用户(仅限中国)': 'Chinese users (China only)',
  '其他国家用户': 'Users in other countries',
  '请输入YOU2邮箱/交换YOU2点': 'Please enter you2 mailbox / exchange point',
  '提币': 'Withdraw money',
  '手续费': 'Service Charge',
  '请输入提币数量': 'Please enter the withdrawal quantity',
  '可用': 'available',
  '全部': 'whole',
  '验证助记词': 'Verification mnemonics',
  'TT资产列表': 'TT asset list',
  'CYTT资产列表': 'Cytt asset list',
  '提现': 'Withdrawal',
  '资产明细': 'Asset details',
  '充值': 'Recharge',
  '旅游出行': 'Travel',
  'C2C购买': 'C2C purchase',
  '没有更多了': 'No more',
  '加速收益': 'Accelerated revenue',
  '钱包名称已存在': 'Wallet name already exists',
  '提示': 'Tips',
  '兑换': 'exchange',
  '请上传身份证正面照片': 'Please upload the front photo of your ID card',
  '请上传身份证反面照片': 'Please upload the photo on the back of your ID card',
  '请输入正确身份证号码': 'Please enter the correct ID number.',
  '可获得TC燃料': 'TC fuel available',
  '最低1TT开始兑换': 'Minimum 1tt starts to exchange',
  '钱包导入成功': 'Wallet imported successfully',
  'TT余额不足': 'Insufficient TT balance',
  '订单': 'order',
  '购买订单': 'Purchase order',
  '出售订单': 'Sale order',
  '请选择订单类型查看': 'Please select order type to view',
  '个人收款码': 'Personal collection code',
  '金额': 'amount of money',
  'CYTT数量': 'Number of cytts',
  '已完成': 'Completed',
  '已撤单': 'Cancelled',
  '进行中': 'have in hand',
  '我要撤单': 'I want to cancel the bill',
  '撤单成功': 'Cancellation succeeded',
}
export default en