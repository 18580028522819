import koLocale from 'element-ui/lib/locale/lang/ko'
const ko = {
  ...koLocale,
  '创建钱包': '지갑 만들기',
  '导入钱包': '지갑 가져오기',
  '钱包名称': '지갑 이름',
  '支付密码': '결제 비밀번호',
  '再次输入支付密码': '결제 비밀번호 다시 입력',
  '邀请码': '초대 코드',
  '助记词': '조기사',
  '请输入昵称': '닉네임을 입력하세요.',
  '请输入支付密码': '결제 비밀번호를 입력하세요.',
  '请再次输入支付密码': '결제 비밀번호를 다시 입력하십시오.',
  '请输入邀请码': '초대 번호를 입력하십시오.',
  '请输入助记词': '기어를 입력하십시오.',
  '提交': '제출',
  '我已抄写助记词': '나는 이미 조기사를 베꼈다.',
  '抄写助记词': '조기사 베끼기.',
  '验证': '검증',
  '请选择完助记词': '조기사 선택 완료.',
  '警告': '경고',
  '签到': '출석체크.',
  '公告': '공지',
  '旅游': '여행.',
  '更多': '추가',
  '热门旅游': '핫한 여행.',
  '总资产': '총자산',
  '燃料共生': '연료 공생.',
  '共建生态': '공건 생태.',
  '资产': '자산',
  'TT燃料': 'TT 연료.',
  'TC燃料': 'TC 연료',
  '慈善旅游基金': '자선 관광 기금.',
  '共建数量': '공설 수량',
  '共建奖励': '공설 보상',
  '倍': '배',
  '网络错误': '네트워크 오류',
  '邀请好友': '친구 초대.',
  '我的': '나의.',
  '邀请地址': '초대 주소',
  '复制地址': '주소 복사',
  '复制成功': '복사 성공',
  '该浏览器不支持自动复制': '이 브라우저는 자동 복사를 지원하지 않습니다.',
  'TT 兑换 TC': 'TT 교환 TC',
  '数量': '수량',
  '确定': '확인',
  '请输入数量': '수량을 입력하세요.',
  'TT燃料数量': 'TT 연료 수량',
  'TC燃料数量': 'TC 연료량',
  '共生CYTT数量': '공생 CYTT 수량',
  '共生比例': '공생 비율',
  'CYTT B钱包提币': 'CYTTB 지갑 인출',
  '实名认证': '실명 인증',
  '请选择身份进行实名认证': '신분을 선택하여 실명 인증을 하세요',
  '上传人像面': '인상면을 올리다',
  '上传国徽面': '국장면을 올리다',
  '上传图片失败': '그림 업로드 실패',
  '请输入姓名': '이름을 입력하십시오.',
  '请输入您的身份证号码': '주민등록번호를 입력하십시오.',
  '上传手持护照': '여권',
  '中国用户(仅限中国)': '중국 사용자(중국만 해당)',
  '其他国家用户': '기타 국가 사용자',
  '请输入YOU2邮箱/交换YOU2点': 'YOU2 메일박스 입력 / YOU2 교환',
  '提币': '화폐를 인출하다',
  '手续费': '수수료',
  '请输入提币数量': '인출 수량을 입력하세요',
  '可用': '사용 가능',
  '全部': '모조리',
  '验证助记词': '유효성 검사',
  'TT资产列表': 'TT 자산 목록',
  'CYTT资产列表': 'CYTT 자산 목록',
  '提现': '현금을 인출하다',
  '资产明细': '자산 내역',
  '充值': '충전하다',
  '旅游出行': '여행',
  'C2C购买': 'C2C 구매',
  '没有更多了': '더 없어요.',
  '加速收益': '수익 증대',
  '钱包名称已存在': '지갑 이름이 이미 존재합니다.',
  '提示': '프롬프트',
  '兑换': '환전',
  '请上传身份证正面照片': '신분증 정면 사진 올려주세요.',
  '请上传身份证反面照片': '신분증 뒷모습 사진 올려주세요.',
  '请输入正确身份证号码': '정확한 주민등록번호를 입력하세요.',
  '可获得TC燃料': 'TC 연료 획득 가능',
  '最低1TT开始兑换': '최소 1TT 교환 시작',
  '钱包导入成功': '지갑 가져오기 성공',
  'TT余额不足': 'TT 잔액 부족',
  '订单': '주문서',
  '购买订单': '주문 구입',
  '出售订单': '판매 주문서',
  '请选择订单类型查看': '주문 유형 보기를 선택하십시오.',
  '个人收款码': '개인 수금 코드',
  '金额': '금액',
  'CYTT数量': 'CYTT 수량',
  '已完成': '완료됨',
  '已撤单': '취소됨',
  '进行中': '진행 중',
  '我要撤单': '취소할게요.',
  '撤单成功': '취소 성공',
}
export default ko