import axios from 'axios'
import router from '../router'
import i18n from '@/assets/i18n/i18n'
import message from '@/utils/message'
import store from '@/store'

// 创建axios实例
const service = axios.create({
  // baseURL: Base_url(), // api的base_url
  timeout: 60000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(config => {
  config.headers['LANGUAGE'] = i18n.locale
  config.headers['MAC'] = 'mac'
  config.headers['TOKEN'] = sessionStorage.getItem('TOKEN')
  return config
}, error => {
  // Do something with request error
  Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
  response => {
    /**
    * code为非0是抛错 可结合自己业务进行修改
    */
    if (response.data.code === '999') {
      if (!store.state.isPc)
        router.replace({
          path: '/login_phone'
        })
      else
        router.replace({
          path: '/login'
        })
      return ''
    } else {
      return response.data
    }
  },
  error => {
    console.info('->error', error);
    message.showNotify('网络错误', '警告', 'warning')
    return Promise.reject(error)
  }
)

export default service
