<template>
  <div class="app home" v-loading="pageLoading">
    <!-- <img alt="Vue logo" :src="require('@/assets/logo.png')"> -->
    <div class="cont">
      <div class="btnList mb10 displayRow justifyEnd">
        <el-button :disabled="info.is_qian_dao ? true : false" @click="qiandao">{{$t('签到')}}</el-button>
      </div>
      <el-carousel height="320px">
        <el-carousel-item v-for="(item,k) in info.banners" :key="k">
          <img :src="item.img" alt="">
        </el-carousel-item>
      </el-carousel>

      <div class="notice mt10 displayRow justifyStart">
        <!-- <h4 class="span">公告</h4> -->
        <span class="span">{{$t('公告')}}</span>
        <el-carousel height="30px" direction="vertical" :autoplay="true">
          <el-carousel-item v-for="(item,k) in info.gongaos" :key="k">
            <span>{{item.title}}</span>
          </el-carousel-item>
        </el-carousel>
      </div>

      <div class="displayRow justifyBetween">
        <h4>{{$t('旅游')}}</h4>
        <el-button style="height:50px" size="small" @click="getMore">{{$t('更多')}}</el-button>
      </div>
      <div class="hotTrip displayRow wrap justifyStart">
        <div class="item mb10" v-for="(i,k) in info.hotLvYou" :key="k">
          <img :src="i.pic" alt="">
          <span style="text-align:center">{{i.guojia_name}}</span>
        </div>
      </div>
    </div>

    <el-drawer :visible.sync="drawer" :title="$t('热门旅游')" direction="rtl" size="400px">
      <div class="infinite-list hotTrip displayColumn justifyStart" v-infinite-scroll="load" style="overflow:auto">
        <div class="item mb10" v-for="(i,k) in tripMore" :key="k">
          <img :src="i.pic" alt="">
          <span>{{i.guojia_name}}</span>
        </div>
        <p style="text-align:center" v-if="nomoreNew">没有更多了</p>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import basicApi from '@/api/basic'
import message from '@/utils/message'
import i18n from '@/assets/i18n/i18n'

export default {
  name: 'Home',
  components: {},
  filters: {
    ellipsis (val) {
      if (val) {
        return val.replace('http:', '')
      } else {
        return ''
      }
    }
  },
  computed: {
    language () {
      return i18n.locale
    }
  },
  created () {
    if (!sessionStorage.getItem('home')) {
      this.getInfo()
    } else {
      this.info = JSON.parse(sessionStorage.getItem('home'))
    }
    // setTimeout(() => {
    //   const referrer = document.getElementById("referrer");
    //   referrer.setAttribute("content", "never")
    // }, 2000);
  },
  data () {
    return {
      info: {},
      pageLoading: false,

      drawer: false,
      tripMore: [],
      page: 1,
      nomoreNew: false
    }
  },
  watch: {
    language () {
      this.getInfo()
    }
  },
  methods: {
    getInfo () {
      this.pageLoading = true
      basicApi.getIndex().then(res => {
        this.pageLoading = false
        if (res.code === '200') {
          this.info = res.data
          sessionStorage.setItem('home', JSON.stringify(res.data))
        }
      })
    },
    qiandao () {
      basicApi.addQiandao(this.info.qian_dao_tc_num).then(res => {
        if (res.code === '200') {
          message.showNotify(res.msg)
          this.getInfo()
        } else {
          message.showNotify(res.msg, '警告', 'warning')
        }
      })
    },
    getMore () {
      this.page = 1
      this.nomoreNew = false
      basicApi.getIndexMore(this.page).then(res => {
        this.tripMore = res.data.lvYou
        this.drawer = true
      })
    },
    load () {
      if (this.nomoreNew) return
      ++this.page
      basicApi.getIndexMore(this.page).then(res => {
        this.tripMore = this.tripMore.concat(res.data.lvYou)
        if (res.data.lvYou.length < 1) {
          this.nomoreNew = true
        }
      })
    }
  }
}
</script>
