/*
 * @Description:
 * @Version: 1.0
 * @Autor: Bbobos
 * @Date: 2020-07-13 13:22:18
 * @LastEditTime: 2020-07-24 14:16:41
 */
import Vue from "vue";
// 在这里引入你所需的组件
import { Col, Row, Button, Toast, Grid, GridItem, NavBar, Icon, Dialog, Form, Field, Switch, Lazyload, Loading, Search, Tab, Tabs, Cell, CellGroup, PullRefresh, Divider, Sticky, Collapse, CollapseItem, Picker, Popup, Uploader, Pagination, Checkbox, CheckboxGroup, Tag, AddressEdit, ImagePreview } from "vant";
import { Image as VanImage } from 'vant';
// 按需引入UI组件
Vue.use(Col);
Vue.use(Row);
Vue.use(Toast, Lazyload); //轻提示,Lazyload
Vue.use(Dialog);//弹出框
Vue.use(Lazyload, {
  lazyComponent: true,
});
Vue.use(Button); // 按钮
Vue.use(NavBar); // 导航栏
Vue.use(Grid); // 宫格
Vue.use(GridItem); // 宫格
Vue.use(Icon); //cion
Vue.use(Form); //表单
Vue.use(Field); //输入框
Vue.use(Switch); //Switch 开关
Vue.use(VanImage); //图片
Vue.use(Loading); //加载
Vue.use(Search);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(PullRefresh); //下拉刷新
Vue.use(Divider); //分割线
Vue.use(Sticky); //吸顶
Vue.use(Collapse); //折叠面板
Vue.use(CollapseItem); //折叠面板
Vue.use(Picker); //选择器
Vue.use(Popup); //弹出层
Vue.use(Uploader); //文件上传
Vue.use(Pagination); //分页
Vue.use(Checkbox); //复选框
Vue.use(CheckboxGroup);
Vue.use(Tag); // 标记
Vue.use(AddressEdit); // AddressEdit 地址编辑
Vue.use(ImagePreview); //ImagePreview 图片预览
