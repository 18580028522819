/*
 * @Description: 
 * @Version: 1.0
 * @Autor: Bbobos
 * @Date: 2020-03-12 19:38:31
 * @LastEditTime: 2020-11-26 21:00:26
 */
import commonI18n from '@/assets/i18n/commonI18n'
import { Notification } from 'element-ui'


export default {
  showNotify (message = '成功', title = '成功', type = 'success', duration = '2500') {
    Notification({
      title: commonI18n(title),
      message: commonI18n(message),
      type: type,
      duration: duration
    })
  }
}
