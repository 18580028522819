import request from '@/utils/request'
// const befUrl = process.env.VUE_APP_URL
// const befUrl = 'http://183.63.53.52:8000/api'
const befUrl = 'https://webapicytt.turingnetwork.com/api'

export default {
  login (params) {
    return request({
      url: befUrl + '/Login/zhuce',
      method: 'post',
      data: params
    })
  },
  zjcVerify (zjcStr, uid) {
    return request({
      url: befUrl + '/Login/zjcVerify',
      method: 'post',
      data: {
        uid: uid,
        zjcStr: zjcStr
      }
    })
  },
  exportMoneyPack (params) {
    return request({
      url: befUrl + '/Login/exportMoneyPack',
      method: 'post',
      data: params
    })
  },
  getMoneyIndex () {
    return request({
      url: befUrl + '/money/index',
      method: 'post'
    })
  },
  getTTMoneyList (page) {
    return request({
      url: befUrl + '/money/moneyList',
      method: 'post',
      data: {
        page: page,
        type: 1
      }
    })
  },
  getCYTTMoneyList (page) {
    return request({
      url: befUrl + '/money/moneyList4',
      method: 'post',
      data: {
        page: page,
        type: 4,
        status: 2
      }
    })
  },
  getIndex () {
    return request({
      url: befUrl + '/Index/index',
      method: 'post'
    })
  },
  getIndexMore (page) {
    return request({
      url: befUrl + '/Index/findByMore',
      method: 'post',
      data: {
        page: page,
        status: 1
      }
    })
  },
  addQiandao (num) {
    return request({
      url: befUrl + '/Index/addQiandao',
      method: 'post',
      data: {
        qian_dao_tc_num: num
      }
    })
  },
  getRanLiaoGs (tt, pwd) {
    return request({
      url: befUrl + '/Money/getRanLiaoGs',
      method: 'post',
      data: {
        tt_num: tt,
        pay_password: pwd
      }
    })
  },
  getGongjianSt () {
    return request({
      url: befUrl + '/Money/getGongjianSt',
      method: 'post',
      data: {
        page: 1
      }
    })
  },
  addGongjianSt (params) {
    return request({
      url: befUrl + '/Money/addGongjianSt',
      method: 'post',
      data: params
    })
  },
  yaoQingF () {
    return request({
      url: befUrl + '/User/yaoQingF',
      method: 'post'
    })
  },
  getTtduihuanTc (params) {
    return request({
      url: befUrl + '/Money/getTtduihuanTc',
      method: 'post',
      data: params
    })
  },

  tibiPage3 () {
    return request({
      url: befUrl + '/Money/tibiPage3',
      method: 'post'
    })
  },
  getIsShiming () {
    return request({
      url: befUrl + '/user/getIsShiming',
      method: 'post'
    })
  },
  getQNY () {
    return request({
      url: befUrl + '/News/qiniu',
      method: 'post'
    })
  },
  isCertification (params) {
    return request({
      url: befUrl + '/user/isCertification',
      method: 'post',
      data: params
    })
  },
  isOthercountry (params) {
    return request({
      url: befUrl + '/user/isOthercountry',
      method: 'post',
      data: params
    })
  },
  tibiMethod (params) {
    return request({
      url: befUrl + '/Money/tibiMethod',
      method: 'post',
      data: params
    })
  },

  getOrderList (page, status) {
    return request({
      url: befUrl + '/Business/orderList',
      method: 'post',
      data: {
        page: page,
        status: status
      }
    })
  },
  delOrderById (id) {
    return request({
      url: befUrl + '/Business/delOrderById',
      method: 'post',
      data: {
        order_id: id
      }
    })
  }
}