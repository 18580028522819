<template>
  <div class="app wallet_phone" v-loading="pageLoading">
    <div class="mb10 displayColumn alignCenter">
      <h2>{{$t('总资产')}}</h2>
      <div class="t_c mb10 displayRow justifyCenter alignCenter">
        <span>{{ sum_zichan(info.sum_zichan,show) }} USDT</span>
        <i class="ml10 el-icon-view" style="cursor: pointer;" :size="20" @click="isShow"></i>
      </div>
    </div>

    <van-cell :title="$t('燃料共生')" is-link @click="fun1" />
    <van-cell :title="$t('共建生态')" is-link @click="fun2" />

    <van-cell :title="$t('TT燃料')" :value="info.tt" is-link @click="checkTT" />
    <van-cell :title="$t('TC燃料')" :value="info.tc" />
    <van-cell :title="$t('USDT')" :value="info.usdt" />
    <van-cell :title="$t('CYTT')" :value="info.cytt" is-link @click="checkCYTT" />
    <van-cell :title="$t('慈善旅游基金')" :value="info.ly_num" />

    <van-popup v-model="visible" position="bottom">
      <div class="t mb10">{{$t('燃料共生')}}</div>
      <div class="tip mb10">{{$t('共生比例')}} 10 TT : 1 TC = 10 CYTT</div>
      <el-form ref="formRef" :model="form" label-width="120px" label-position="left">
        <el-form-item :label="$t('TT燃料数量')" prop="tt" required>
          <el-input v-model="form.tt" type="number" @input="changeTT">
            <template #append>TT</template>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('TC燃料数量')" prop="tc">
          <el-input v-model="form.tc" disabled>
            <template #append>TC</template>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('共生CYTT数量')" prop="cytt">
          <el-input v-model="form.cytt" disabled>
            <template #append>CYTT</template>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('支付密码')" prop="pay_password" required>
          <el-input v-model="form.pay_password">
          </el-input>
        </el-form-item>
      </el-form>
      <div class="submit">
        <el-button @click="visible = false">Cancel</el-button>
        <el-button type="primary" @click="submitFun1" v-loading="formLoading">Confirm</el-button>
      </div>
    </van-popup>
    <van-popup v-model="drawer" position="bottom">
      <div class="t mb10">{{$t('共建生态')}}</div>
      <div class="item displayColumn jusctifyStart alignStart mb10" v-for="(i,k) in gongjianstArr" :key="k">
        <div class="size14">{{i.licai_name}}</div>
        <div class="displayRow justifyBetween alignCenter" style="width:100%">
          <div class="displayColumn justifyStart alignStart">
            <div class="size14">{{$t('共建数量')}}: {{i.min_num + '-' + i.num}} CYTT</div>
            <div class="size14">{{$t('共建奖励')}}: {{i.fangda_beishu + $t('倍')}}</div>
          </div>
          <el-button type="primary" @click="gjst(i)">
            {{$t('共建生态')}}
          </el-button>
        </div>
      </div>
    </van-popup>
    <van-popup class="w9" v-model="visible2" position="center">
      <div class="t mb10 size14">{{dialogT + ' '+ $t('共建生态')}}</div>
      <el-form ref="formRef2" :model="form2" label-width="120px" label-position="left">
        <el-form-item :label="$t('共建数量')" prop="touzi_money" required>
          <el-input v-model="form2.touzi_money" type="number">
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('支付密码')" prop="pay_password" required>
          <el-input v-model="form2.pay_password">
          </el-input>
        </el-form-item>
      </el-form>
      <div class="submit">
        <el-button @click="visible2 = false">Cancel</el-button>
        <el-button type="primary" @click="submitFun2" v-loading="form2Loading">Confirm</el-button>
      </div>
    </van-popup>

    <el-drawer :visible.sync="drawer2" :title="$t(drawer2Tit)" size="360px">
      <div class="infinite-list" v-if="drawer2List && drawer2List.length>0" v-infinite-scroll="load" style="overflow:auto">
        <div class="displayRow justifyBetween alignCenter mb10" style="padding:6px" v-for="(i,k) in drawer2List" :key="k">
          <div class="displayColumn justifyStart alignStart">
            <div style="font-weight:bold">{{$t(i.type)}}</div>
            <div>{{i.add_time}}</div>
          </div>
          <div style="font-weight:bold">{{(i.status ? '+' : '-') + i.money}}</div>
        </div>
        <p v-if="noMore">{{$t('没有更多了')}}</p>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import basicApi from '@/api/basic'
import md5 from 'js-md5';
import message from '@/utils/message'
import Clipboard from 'clipboard'

export default {
  name: 'wallet',
  computed: {
    sum_zichan () {
      return (a, b) => {
        if (b) {
          return a
        } else {
          return '******'
        }
      }
    }
  },
  data () {
    return {
      pageLoading: false,
      info: {},
      show: false,
      showMore: false,

      visible: false,
      form: {},
      formLoading: false,

      fun2Loading: false,

      drawer: false,
      gongjianstArr: [],

      form2: {},
      dialogT: '',
      dialogRow: {},
      visible2: false,
      form2Loading: false,

      drawer2: false,
      drawer2Tit: '',
      drawer2List: [],
      cyttPage: 1,
      noMore: false,
    }
  },
  created () {
    if (!sessionStorage.getItem('wallet')) {
      this.getInfo()
    } else {
      this.info = JSON.parse(sessionStorage.getItem('wallet'))
    }
  },
  methods: {
    getInfo () {
      this.pageLoading = true
      basicApi.getMoneyIndex().then(res => {
        this.pageLoading = false
        this.info = res.data
        sessionStorage.setItem('wallet', JSON.stringify(res.data))
      })
    },
    isShow () {
      this.show = !this.show
    },
    isShowMore () {
      this.showMore = !this.showMore
    },
    fun1 () {
      this.visible = true
    },
    changeTT ($event) {
      this.form.tc = $event / 10
      this.form.cytt = $event
    },
    submitFun1 () {
      this.$refs['formRef'].validate((valid) => {
        if (valid) {
          this.formLoading = true
          basicApi.getRanLiaoGs(this.form.tt, md5(md5(this.form.pay_password) + '~*-=')).then(res => {
            if (res.code === '200') {
              message.showNotify(res.msg)
              this.visible = false
              this.getInfo()
            } else {
              message.showNotify(res.msg, '警告', 'warning')
            }
            this.formLoading = false
          })
        }
      })
    },
    fun2 () {
      this.fun2Loading = true
      basicApi.getGongjianSt().then(res => {
        this.fun2Loading = false
        this.gongjianstArr = res.data
        this.drawer = true
      }).catch(() => {
        this.fun2Loading = false
      })
    },
    gjst (row) {
      this.dialogT = row.licai_name
      this.dialogRow = row
      this.visible2 = true
    },
    submitFun2 () {
      this.$refs['formRef2'].validate((valid) => {
        if (valid) {
          this.form2Loading = true
          var params = {
            // status: this.dialogRow.status,
            status: '2',
            touzi_money: this.form2.touzi_money,
            pay_password: md5(md5(this.form2.pay_password) + '~*-='),
            licai_id: this.dialogRow.id
          }
          basicApi.addGongjianSt(params).then(res => {
            if (res.code === '200') {
              message.showNotify(res.msg)
              this.dialogT = ''
              this.dialogRow = {}
              this.visible2 = false
            } else {
              message.showNotify(res.msg, '警告', 'warning')
            }
            this.form2Loading = false
          })
        }
      })
    },


    checkTT () {
      this.cyttPage = 1
      this.noMore = false
      basicApi.getTTMoneyList(this.cyttPage).then(res => {
        this.drawer2Tit = 'TT资产列表'
        this.drawer2List = res.data.list
        this.drawer2 = true
      })
    },
    checkCYTT () {
      this.cyttPage = 1
      this.noMore = false
      basicApi.getCYTTMoneyList(this.cyttPage).then(res => {
        this.drawer2Tit = 'CYTT资产列表'
        this.drawer2List = res.data.list
        this.drawer2 = true
      })
    },
    load () {
      if (this.noMore) return
      ++this.cyttPage
      if (this.drawer2Tit === 'CYTT资产列表') {
        basicApi.getCYTTMoneyList(this.cyttPage).then(res => {
          this.drawer2List = this.drawer2List.concat(res.data.list)
          if (res.data.list.length < 1) {
            this.noMore = true
          }
        })
      } else {
        basicApi.getTTMoneyList(this.cyttPage).then(res => {
          this.drawer2List = this.drawer2List.concat(res.data.list)
          if (res.data.list.length < 1) {
            this.noMore = true
          }
        })
      }
    }
  }
}
</script>