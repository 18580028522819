import thLocale from 'element-ui/lib/locale/lang/fr'
const fr = {
  ...thLocale,
  '创建钱包': 'Créer un portefeuille',
  '导入钱包': 'Importer un portefeuille',
  '钱包名称': 'Nom du portefeuille',
  '支付密码': 'Mot de passe de paiement',
  '再次输入支付密码': 'Saisissez à nouveau le mot de passe de paiement',
  '邀请码': `Code d'invitation`,
  '助记词': 'Mnémonique',
  '请输入昵称': 'Veuillez saisir un surnom',
  '请输入支付密码': 'Veuillez saisir le mot de passe de paiement',
  '请再次输入支付密码': 'Veuillez saisir à nouveau le mot de passe de paiement',
  '请输入邀请码': `Veuillez saisir le Code d'invitation`,
  '请输入助记词': 'Veuillez saisir un mnémonique',
  '提交': 'Présentation',
  '我已抄写助记词': `J'ai copié les mnémoniques.`,
  '抄写助记词': 'Transcrire les mnémoniques',
  '验证': 'Validation',
  '请选择完助记词': 'Veuillez sélectionner les mnémoniques',
  '警告': 'Avertissement',
  '签到': 'Inscrivez - vous.',
  '公告': 'Annonces',
  '旅游': 'Tourisme',
  '更多': 'Plus',
  '热门旅游': 'Tourisme populaire',
  '总资产': 'Total des actifs',
  '燃料共生': 'Symbiose des combustibles',
  '共建生态': 'Co - construction écologique',
  '资产': 'Actifs',
  'TT燃料': 'Tt carburant',
  'TC燃料': 'Carburant TC',
  '慈善旅游基金': 'Charitable Tourism Fund',
  '共建数量': 'Nombre de co - constructions',
  '共建奖励': 'Prix de co - Construction',
  '倍': 'Multiple',
  '网络错误': 'Erreur de réseau',
  '邀请好友': 'Inviter des amis',
  '我的': 'La mienne.',
  '邀请地址': `Adresse de l'invitation`,
  '复制地址': `Copier l'adresse`,
  '复制成功': 'Copie réussie',
  '该浏览器不支持自动复制': `La copie automatique n'est pas prise en charge par ce navigateur`,
  'TT 兑换 TC': 'Tt Exchange TC',
  '数量': 'Nombre',
  '确定': `C'est sûr.`,
  '请输入数量': 'Veuillez entrer la quantité',
  'TT燃料数量': 'Tt quantité de carburant',
  'TC燃料数量': 'Quantité de carburant TC',
  '共生CYTT数量': 'Nombre de CYTS symbiotiques',
  '共生比例': 'Taux de symbiose',
  'CYTT B钱包提币': 'Retrait de pièces du portefeuille cyttb',
  '实名认证': 'Authentification par nom réel',
  '请选择身份进行实名认证': `Veuillez sélectionner l'identité pour l'authentification du nom réel`,
  '上传人像面': 'Télécharger le portrait',
  '上传国徽面': `Télécharger l'emblème national`,
  '上传图片失败': `Impossible de télécharger l'image`,
  '请输入姓名': 'Veuillez saisir un nom',
  '请输入您的身份证号码': `Veuillez saisir votre numéro d'identification`,
  '上传手持护照': 'Télécharger un passeport portatif',
  '中国用户(仅限中国)': 'Utilisateurs chinois (Chine seulement)',
  '其他国家用户': 'Autres utilisateurs nationaux',
  '请输入YOU2邮箱/交换YOU2点': 'Veuillez saisir you2 mailbox / Exchange you2 point',
  '提币': 'Retrait de pièces',
  '手续费': 'Frais de manutention',
  '请输入提币数量': 'Veuillez saisir la quantité de retrait',
  '可用': 'Disponible',
  '全部': 'Tous',
  '验证助记词': 'Vérifier les mnémoniques',
  'TT资产列表': 'Tt liste des actifs',
  'CYTT资产列表': 'Liste des actifs du cytt',
  '提现': 'Retrait',
  '资产明细': 'Ventilation des actifs',
  '充值': 'Recharge',
  '旅游出行': 'Voyages touristiques',
  'C2C购买': 'Achats c2c',
  '没有更多了': `Il n'y en a plus.`,
  '加速收益': 'Gains accélérés',
  '钱包名称已存在': 'Le nom du portefeuille existe déjà',
  '提示': 'Conseils',
  '兑换': 'Change',
  '请上传身份证正面照片': `Veuillez télécharger la photo avant de votre carte d'identité`,
  '请上传身份证反面照片': `Veuillez télécharger la photo au verso de votre carte d'identité`,
  '请输入正确身份证号码': `Veuillez saisir le bon numéro d'identification`,
  '可获得TC燃料': 'Carburant TC disponible',
  '最低1TT开始兑换': '1 tt minimum pour commencer à échanger',
  '钱包导入成功': 'Portefeuille importé avec succès',
  'TT余额不足': 'Solde tt insuffisant',
  '订单': 'Ordre',
  '购买订单': 'Bon de commande',
  '出售订单': 'Ordre de vente',
  '请选择订单类型查看': 'Veuillez sélectionner le type de commande à afficher',
  '个人收款码': 'Code de collecte personnel',
  '金额': 'Montant',
  'CYTT数量': 'Nombre de cytt',
  '已完成': 'Terminé',
  '已撤单': 'Ordre annulé',
  '进行中': 'En cours',
  '我要撤单': 'Je dois annuler la commande.',
  '撤单成功': 'Annulation réussie',
}
export default fr