<template>
  <div class="app">
    <div id="nav" v-if="name != 'login' && !name.includes('_phone')">
      <router-link to="/home">Home</router-link>
      <div class="f">|</div>
      <router-link to="wallet">Wallet</router-link>
      <div class="f">|</div>
      <router-link to="mine">Mine</router-link>
    </div>
    <router-view class="route" style="position:relation" />
    <el-select class="lang" v-model="value" placeholder="请选择" @change="setLanguage">
      <el-option v-for="item in langOptions" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
    <van-tabbar v-if="name.includes('_phone') && !name.includes('login')" v-model="tabActive" @change="onChange">
      <van-tabbar-item name="home" icon="home-o">Home</van-tabbar-item>
      <van-tabbar-item name="wallet" icon="balance-o">Wallet</van-tabbar-item>
      <van-tabbar-item name="mine" icon="friends-o">Mine</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import i18n from '@/assets/i18n/i18n'

export default {
  name: 'app',
  computed: {
    name () {
      return this.$route.name
    },
    langOptions () {
      return [
        { value: 'en', label: 'English' },
        { value: 'zh', label: '中' },
        { value: 'th', label: 'ภาษาไทย' },
        { value: 'ko', label: '한국어 공부 해요.' },
        { value: 'ja', label: '日本語' },
        { value: 'fr', label: 'Français' },
        { value: 'id', label: 'Indonesia' },
        { value: 'vi', label: 'Ngôn ngữ Yue' },]
    }
  },
  data () {
    return {
      value: 'zh',
      fullHeight: 0,
      clientWidth: 0,
      tabActive: 'wallet'
    }
  },
  created () {
    this.value = i18n.locale
    if (this.name) {
      if (this.name.includes('home'))
        this.tabActive = 'home'
      else if (this.name.includes('wallet'))
        this.tabActive = 'wallet'
      else
        this.tabActive = 'mine'
    } else {
      this.$router.push({ path: 'home' })
    }

  },
  watch: {
    fullHeight (val) {
      // 定时器是为了优化，如果频繁调用window.onresize方法会造成页面卡顿，
      // 增加定时器会避免频繁调用window.onresize方法
      if (!this.timer) {
        this.fullHeight = val;
        this.$store.commit("changeFullHeight", val);
        this.timer = true;
        let that = this;
        setTimeout(function () {
          that.timer = false;
        }, 400);
      }
    },
    clientWidth (val) {
      if (!this.timer) {
        this.fullHeight = val;
        this.$store.commit("changeIsPc", val > 900);
        if (val > 900) {
          if (this.name.includes('_phone')) {
            this.$router.push({ path: this.name.replace(/_phone/, '') })
          }
        } else {
          if (!this.name.includes('_phone')) {
            this.$router.push({ path: this.name + '_phone' })
          }
        }
        this.timer = true;
        let that = this;
        setTimeout(function () {
          that.timer = false;
        }, 400);
      }
    },
    name (val) {
      if (val.includes('home'))
        this.tabActive = 'home'
      else if (val.includes('wallet'))
        this.tabActive = 'wallet'
    }
  },
  mounted () {
    const that = this;
    that.fullHeight = `${document.documentElement.clientHeight}`;
    that.clientWidth = `${document.documentElement.clientWidth}`;
    that.$store.commit("changeIsPc", that.clientWidth > 900);
    if (that.clientWidth > 900) {
      if (that.name.includes('_phone')) {
        that.$router.push({ path: that.name.replace(/_phone/, '') })
      }
    } else {
      if (!that.name.includes('_phone')) {
        that.$router.push({ path: that.name + '_phone' })
      }
    }
    window.onresize = function () {
      that.fullHeight = `${document.documentElement.clientHeight}`;
      that.clientWidth = `${document.documentElement.clientWidth}`;
    }
  },
  methods: {
    setLanguage (val) {
      this.$i18n.locale = val;
    },
    onChange (val) {
      this.$router.push({ path: val + (this.$store.state.isPc ? '' : '_phone') })
    }
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
