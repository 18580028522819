/*
 * @Description: 
 * @Version: 1.0
 * @Autor: Bbobos
 * @Date: 2020-03-25 22:07:29
 * @LastEditTime: 2020-03-25 22:07:55
 */

import en from './en' //英
import zh from './cn' //中
import th from './th' //泰
import ko from './ko' //韩
import ja from './ja' //日
import fr from './fr' //法
import vi from './vi' //越
import id from './id' //印尼

export default {
  en, zh, th, ko, ja, fr, vi, id
}